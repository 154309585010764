import { any } from 'prop-types';
import React from 'react';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { EditAsideTitle } from '@nodeTypeConfig/configTypes';
import AsideEditor from '@UI/editor/AsideEditor';
import { useSelector } from 'react-redux';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { ContentHref } from '@generalTypes/apiTypes';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideTitle: React.FC<{
  config: EditAsideTitle;
}> = ({ config }) => {
  const editHref = useEditHref();
  const nodeKey = getResourceKey(editHref);

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const changeMessage = useSelector((state: RootState) =>
    selectChangeMessageForHtmlString(
      state,
      state.documentUI.currentEditingNode as ContentHref,
      config
    )
  );

  return (
    <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator htmlChangeMessage={changeMessage} />
        <label className="control-label">{config.label}</label>
        <div className="form-control">
          <AsideEditor
            field={config.property}
            initial={initialValue}
            skey={nodeKey}
            readOnly={readOnly}
            inline
            placeholder={`Vul een ${config.label.toLowerCase()} in...`}
          />
        </div>
      </AsideValidation>
    </div>
  );
};

AsideTitle.displayName = 'AsideTitle';

export default AsideTitle;

AsideTitle.propTypes = {
  config: any,
};
