import { arrayToObjectMap } from '@newStore/genericHelpers';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Content, ContentHref, ContentRelation, ContentRelationHref } from '@generalTypes/apiTypes';

export type ReferenceFrame = {
  href: ContentHref;
  content: Record<ContentHref, Content>;
  relations: Record<ContentRelationHref, ContentRelation>;
  isLoaded: boolean;
};

export type ReferenceFrameState = Record<ContentHref, ReferenceFrame>;

const initialState: ReferenceFrameState = {};

const referenceFramesSlice = createSlice({
  name: 'referenceFrames',
  initialState,
  reducers: {
    loadReferenceFrame(state, action: PayloadAction<{ referenceFrame: ContentHref }>) {
      const { referenceFrame } = action.payload;

      if (!state[referenceFrame]) {
        state[referenceFrame] = {
          href: referenceFrame,
          content: {},
          relations: {},
          isLoaded: false,
        };
      }
    },
    loadReferenceFrameSuccess(
      state,
      action: PayloadAction<{
        referenceFrame: ContentHref;
        content: Record<string, Content>;
        relations: ContentRelation[];
      }>
    ) {
      const { referenceFrame, content, relations } = action.payload;
      state[referenceFrame].content = content;
      state[referenceFrame].relations = arrayToObjectMap(relations);
      state[referenceFrame].isLoaded = true;
    },
  },
});

export const { loadReferenceFrame, loadReferenceFrameSuccess } = referenceFramesSlice.actions;
export default referenceFramesSlice.reducer;
