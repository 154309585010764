import { Modal } from '@kathondvla/react-shared-components/src/components';
import { Button } from '@mui/material';
import { Component } from 'react';

interface ModalErrorBoundaryState {
  hasError: boolean;
}

interface ModalErrorBoundaryProps {
  children: React.ReactNode;
  onClose: () => void;
}

class ModalErrorBoundary extends Component<ModalErrorBoundaryProps, ModalErrorBoundaryState> {
  constructor(props: ModalErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <>
        <Modal.Header title={'Fout'} onClose={this.props.onClose} />
        <Modal.Body>
          <div>
            <p>Oeps, er is een fout opgetreden!</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outlined" onClick={this.props.onClose}>
            Sluiten
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default ModalErrorBoundary;
