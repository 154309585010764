import { visualDomDiff } from 'visual-dom-diff';

const diffHtmlContent = (oldHtml: string, newHtml: string): string => {
  const oldDiv = document.createElement('div');
  oldDiv.innerHTML = oldHtml;

  const newDiv = document.createElement('div');
  newDiv.innerHTML = newHtml;

  const htmldiff = visualDomDiff(oldDiv, newDiv, {
    addedClass: 'diff-insert',
    removedClass: 'diff-delete',
    skipModified: true,
  });

  return (htmldiff?.firstChild as Element)?.innerHTML;
};

export const htmlDiffFunction = (newValue: string, oldValue: string): string => {
  return diffHtmlContent(oldValue, newValue);
};
