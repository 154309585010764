import React, { useEffect } from 'react';
import { patchNodeAction, resetNamedSetsAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { sortBy } from 'lodash';
import { any } from 'prop-types';
import { EditAsideNamedSet, EditComponentProperty } from '@nodeTypeConfig/configTypes';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsNodeReadOnly, selectIsRoot } from '@newStore/documentUI/documentUISelectors';
import { selectPathToRoot, selectRootItem } from '@newStore/documentApi/documentApiSelectors';
import { selectNamedSetsForTag } from '@newStore/externalData/externalDataSelectors';
import { ContentHref, NamedSet, NamedSetHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { createTypedSelector } from '@newStore/genericHelpers';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';
import CustomTooltip from '@UI/tooltip/CustomTooltip';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import AsideValidation from '../asideValidationErrors/AsideValidation';

import './AsideNamedSet.scss';

const empty = [];

const tooltipContent = {
  Middenkader: 'ict-coördinator, zorgcoördinator, graadcoördinator, technisch adviseur,…',
  'Ondersteunend personeel': 'administratief en opvoedend personeel',
};

const selectInheritedPropertyValue = createTypedSelector(
  [
    (state: RootState, property: EditComponentProperty) =>
      selectCurrentEditingNode(state)?.[property],
    (state: RootState) =>
      selectPathToRoot(state, state.documentUI.currentEditingNode as ContentHref),
    (state: RootState, property: EditComponentProperty) => property,
  ],
  (currentValue, pathToRoot, property) => {
    // the value is either the currentValue of the editingNode, or the value of one of it parents (traversing up the tree)
    return currentValue || pathToRoot.find((node) => node[property])?.[property] || empty;
  }
);

/**
 * in a Teaser the positions are readonly when there are themes selected
 */
const selectIsReadOnly = createTypedSelector(
  [
    (state: RootState) =>
      Boolean(
        !state.documentUI.currentEditingNode ||
          selectIsNodeReadOnly(state, state.documentUI.currentEditingNode)
      ),
    (state: RootState) => selectCurrentEditingNode(state),
    (state: RootState, property: EditComponentProperty) => property,
  ],
  (readOnly, currentValue, property) => {
    const isReadOnly =
      readOnly ||
      (property === 'positions' &&
        currentValue.type === 'TEASER' &&
        (currentValue.themes ?? [])?.length > 0);
    return isReadOnly;
  }
);

const selectNamedSetOptionsForTag = createTypedSelector(
  [
    (state: RootState, tag: string, _property: EditComponentProperty) =>
      selectNamedSetsForTag(state, tag),
    (state: RootState, tag: string, property: EditComponentProperty) =>
      selectInheritedPropertyValue(state, property),
  ],
  (namedSets: NamedSet[] | null, currentValue: NamedSetHref[]) => {
    return sortBy(
      namedSets?.map((option) => ({
        name: option.name,
        key: option.key,
        href: option.$$meta.permalink,
        isChecked: Boolean(
          currentValue.some((selected) => getResourceKey(selected) === option.key)
        ),
        tooltip: tooltipContent[option.name],
      })) || [],
      ['name']
    );
  }
);

const AsideNamedSet: React.FC<{
  config: EditAsideNamedSet;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useSelector((state: RootState) => selectIsReadOnly(state, config.property));
  const inheritedPropertyValue: NamedSetHref[] = useSelector((state: RootState) =>
    selectInheritedPropertyValue(state, config.property)
  );

  const itemValue: NamedSetHref[] | undefined = useInitialValue(config.property);

  const hasRootNamedSets = useSelector((state: RootState) =>
    Boolean(selectRootItem(state)?.[config.property]?.length)
  );
  const isRoot = useSelector((state: RootState) => selectIsRoot(state, editHref));

  const namedSetOptions = useSelector((state: RootState) =>
    selectNamedSetOptionsForTag(state, config.tag, config.property)
  );

  const isInherited = !isRoot && !itemValue;

  useEffect(() => {
    if (config.tag) {
      dispatch(getAllOfResource({ resource: pathMap.namedSets, refresh: false }));
    }
  }, [dispatch, config.tag]);

  const change = (value: boolean, key: string) => {
    const currentSelectedNamedSets = inheritedPropertyValue;
    const updatedSelectedNamedSets = value
      ? [...currentSelectedNamedSets, key].filter(
          (item, index, self) => self.indexOf(item) === index
        )
      : currentSelectedNamedSets.filter((item) => item !== key);

    dispatch(
      patchNodeAction(getResourceKey(editHref), { [config.property]: updatedSelectedNamedSets })
    );
  };

  const clearAll = () => {
    dispatch(patchNodeAction(getResourceKey(editHref), { [config.property]: [] }));
  };

  const selectAll = () => {
    const allHrefs = namedSetOptions.map((option) => option.href);
    const updatedSelectedNamedSets = [...new Set([...inheritedPropertyValue, ...allHrefs])];

    dispatch(
      patchNodeAction(getResourceKey(editHref), { [config.property]: updatedSelectedNamedSets })
    );
  };

  const backToInherited = () => {
    dispatch(resetNamedSetsAction(config.property, getResourceKey(editHref)));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <div className={`form-group namedSet${isInherited ? ' inherited' : ''}`}>
        {!isRoot && !hasRootNamedSets && (
          <>
            <label className="control-label">{`${config.label} ${isRoot ? '*' : ''}`}</label>
            <div>
              <label className="warning-label">
                {`De root heeft geen ${config.label} gedefinieerd. Voeg het eerst toe aan het hoofdniveau.`}
              </label>
            </div>
          </>
        )}

        {(isRoot || hasRootNamedSets) && (
          <>
            <div>
              <label className="control-label">{`${config.label} ${isRoot ? '*' : ''}`}</label>
              {isInherited && <span className="inherited-info">Overgenomen</span>}
              {!isInherited && !isRoot && (
                <span className="inherited-revert" onClick={backToInherited}>
                  Overnemen
                </span>
              )}
            </div>
            <div>
              <label className="subtitle-label">Voor wie is deze inhoud bestemd?</label>
            </div>
          </>
        )}

        <div className="row" style={{ marginLeft: '15px', marginRight: '15px' }}>
          <div className="col-md-6">
            {namedSetOptions.map((option) => (
              <div className="checkbox-row" key={option.key}>
                <input
                  id={option.key}
                  type="checkbox"
                  checked={option.isChecked}
                  onChange={(input) => change(input.target.checked, option.href)}
                  disabled={readOnly}
                />
                <label
                  htmlFor={option.key}
                  className={`option${readOnly ? ' aside-component-disabled' : ''}`}
                >
                  {option.name}
                  {option.tooltip && (
                    <CustomTooltip name={option.tooltip} className="inline-tooltip">
                      <sup>
                        <span className="glyphicon glyphicon-question-sign"></span>
                      </sup>
                    </CustomTooltip>
                  )}
                </label>
              </div>
            ))}
          </div>

          {!readOnly && (
            <div className="col-md-12 named-set-actions">
              <a onClick={clearAll}>Alles wissen</a>
              <a onClick={selectAll} style={{ marginLeft: '10px' }}>
                Alles selecteren
              </a>
            </div>
          )}
        </div>
      </div>
    </AsideValidation>
  );
};

export default AsideNamedSet;

AsideNamedSet.displayName = 'AsideNamedSet';

AsideNamedSet.propTypes = {
  config: any,
};
