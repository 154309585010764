import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { BuildingBlockNodeConfig, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { WebSiteHref, WebTemplateHref, ContentType } from '@generalTypes/apiTypes';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import * as apiRoutes from '../../../../reduxLoop/api/apiRoutes';
// import { teasersDeleteValidationCmd } from '../../../reduxLoop/commands/documentListCommands';
import constants from '../../../../reduxLoop/constants/constants';
import { settings } from '../../../settings';
import { proNewsletterAudienceTab } from '../proGenericConfigProps';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '../../helpers/genericComponents';

const teaserImportanceTranslations = {
  LOW: 'Niet opnemen in de nieuwsbrief',
  MEDIUM: 'Gewone teaser',
  HIGH: 'Prioritaire teaser',
};

const TEASER: RootNodeConfig & BuildingBlockNodeConfig = {
  information: {
    definiteArticle: false,
    single: 'PRO.-teaser',
    plural: 'PRO.-teasers',
    ribonIcon: require('../../../../../img/buildingBlocks/TEASER.svg'),
    category: 'PRO',
  },
  webconfiguration: {
    type: 'NEWS_ITEM_AND_TEASER_FALLBACK',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/d153f5d5-78b3-4de7-a231-281da633a8bb' as WebTemplateHref,
    },
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  createModal: {
    authors: {
      addUser: false,
    },
  },
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    {
      ...titleComponent,
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Teaser',
      required: RequiredType.ERROR,
      maxLength: 400,
      options: {
        hideTermButton: true,
      },
    },
    authorsComponent,
    publishComponent,
    {
      component: 'themes',
      label: "Thema's",
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
    {
      property: 'importance',
      label: 'Belangrijkheid',
      component: 'teaserImportance',
      reactComponent: 'AsideTeaserImportance',
      valueToString: (c) => teaserImportanceTranslations[c.importance],
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Afbeelding',
      options: {
        type: 'THUMBNAIL',
        crop: true,
      },
    },
    {
      component: 'teaserLinkedContent',
      reactComponent: 'AsideTeaserLinkedContent',
      label: 'Linkt naar',
      hiddenChildren: {
        containerType: ContentType.TEASER,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'link',
          },
        ],
      },
    },
  ],
  audienceTab: proNewsletterAudienceTab,
  onNewNodeDropped: {
    openAside: false,
  },
  isCreatable: true,
  isSearchable: true,
  isCollapsible: false,
  isImportable: false,
  allowSuggestions: true,
  // customRender: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  confirmDelete: true,
  // customConfirmDeleteMessage: () => '<li>Ben je zeker dat je dit wil verwijderen?</li>',
  hideChildrenInDocument: true,
  customEditorOptions: {
    showMarkerButton: false,
    hideTermButton: true,
  },
};

export default TEASER;
