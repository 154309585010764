import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE: BuildingBlockNodeConfig = {
  information: {
    single: 'Verwijs Stap (volgend)',
    plural: 'Verwijs Stapen (volgend)',
  },

  edit: [
    {
      component: 'developmentPhase',
      reactComponent: 'AsideDevelopmentPhase',
      label: 'Ontwikkelingsfase',
      showInAncestorTypes: [NodeType.CURRICULUM_ZILL_LEERLIJN_AN],
    },
    { component: 'leerlijnReference', label: 'Omschrijving' },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE;
