import { ContentHref } from '@generalTypes/apiTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { sortBy } from 'lodash';
import { format } from 'date-fns';

export const selectVersions = createTypedSelector(
  [
    (state, href: ContentHref) => state.versions[href],
    (state) => state.externalData.data[pathMap.persons].items,
  ],
  (versions, persons) => {
    if (!versions) {
      return null;
    }
    const sortedVersions = sortBy(versions.versions, 'timestamp').reverse();
    return sortedVersions.map((version) => {
      const person = persons[version.person];
      const date = new Date(version.timestamp);

      return {
        date: format(date, 'dd/MM/yyyy HH:mm'),
        personName: person ? `${person.firstName} ${person.lastName}` : '',
      };
    });
  }
);

export const selectIsVersionLoading = createTypedSelector(
  [(state, href: ContentHref) => state.versions[href]],
  (versions) => !versions || versions.isLoading
);
