import { ContentHref, PersonHref, Proposal } from '@generalTypes/apiTypes';
import { Person } from '@generalTypes/personApiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
  selectProposalsForContentMap,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { selectUserHref } from '@newStore/user/userSelectors';
import { EditComponent } from '@nodeTypeConfig/configTypes';
import { shallowEqual } from 'react-redux';
import { selectHiddenDescendants, selectTableOfContentChildren } from '../documentUISelectors';
import { DiffMessage, ProposalMetaInfo, ProposalType } from '../documentUITypes';
import { selectAppliedNodeConfig, selectDocumentRootType } from '../nodeTypeConfigSelectors';
import { htmlDiffFunction } from './asideDiffText';
import { getProposalType } from './diffTextCalculator';
import {
  getAllDescendantProposals,
  getDiffTextForNode,
  getProposalForNode,
  mergeProposals,
  transformProposal,
} from './proposalHelpers';

const emptyArray = [];

/**
 * this selector gets all hrefs that are proposed to be deleted.
 * it is done this way because you can have a proposal to delete a relation to an IS_INCLUDED_IN node (attachment, teaser) that in itself won't be deleted.
 * yet we want to mark it as deleted for this case.
 */
export const selectProposedContentHrefsToDelete = createTypedSelector(
  [
    (state) => selectProposalsForContentMap(state),
    (state) => selectApiWithPendingChanges(state).relationsMap,
  ],
  (proposals, relationsMap) => {
    const proposedContentHrefsToDelete: ContentHref[] = [];
    Object.values(proposals).forEach((proposal) => {
      proposal.listOfRequestedChanges.forEach((change) => {
        if (change.type === 'DELETE') {
          const relation = relationsMap[change.appliesTo.href];
          if (
            relation &&
            (relation.relationtype === 'IS_INCLUDED_IN' || relation.relationtype === 'IS_PART_OF')
          ) {
            proposedContentHrefsToDelete.push(relation.from.href);
          }
        }
      });
    });
    return proposedContentHrefsToDelete;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

const getTransformedProposalWithChildProposalInfo = (
  proposal: Proposal | null,
  type: ProposalType,
  allDescendentProposals: Proposal[],
  personsMap: Record<string, Person>,
  userHref: PersonHref
) => {
  const descendentSummaryProposal = mergeProposals(allDescendentProposals);
  if (proposal) {
    // the node has a proposal itself AND there are children that have a proposal
    return transformProposal(
      {
        ...proposal,
        creators: [
          ...new Set([
            ...proposal.creators,
            ...(descendentSummaryProposal ? descendentSummaryProposal.creators : []),
          ]),
        ],
      },
      personsMap,
      userHref,
      allDescendentProposals.length,
      type
    );
  }
  if (descendentSummaryProposal) {
    // the node has no proposal for itself, but it has children with proposals
    return transformProposal(
      descendentSummaryProposal,
      personsMap,
      userHref,
      allDescendentProposals.length,
      type
    );
  }
  return null;
};

export const selectProposalMetaInfo = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state) => selectProposalsForContentMap(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state, href: ContentHref) => selectHiddenDescendants(state, href),
    selectProposedContentHrefsToDelete,
    (state) => state.externalData.data[pathMap.persons].items,
    (state) => selectUserHref(state),
    (state, href: string) => state.documentUI.collapsedNodes[href],
    (state, href: ContentHref) => href,
    (state) => state.documentUI.mode,
  ],
  (
    contentItem,
    rawContentItem,
    proposalsMap,
    toRelationsMap,
    hiddenDescendants,
    proposedHrefsToDelete,
    personsMap,
    userHref,
    isCollapsed,
    href,
    mode
  ): ProposalMetaInfo | null => {
    if (!userHref) {
      return null;
    }

    if (mode === 'EDIT') {
      return null;
      // let proposalType: ProposalType = 'UPDATE';

      // if (contentItem.$$meta.deleted) {
      //   proposalType = 'DELETE';
      // } else if (!rawContentItem) {
      //   proposalType = 'CREATE';
      // }

      // if (contentItem === rawContentItem) {
      //   return null;
      // }

      // return transformProposal(null, personsMap, userHref, 0, proposalType);
    }

    const proposal = getProposalForNode(href, proposalsMap, contentItem, hiddenDescendants);
    if (isCollapsed) {
      // use case for collapsible nodes where there needs to be an indicator if it has children that have proposals
      // it is not a merge in the same way as with the hidden proposals.
      // We just say there are proposals for the children but we do not specify the changes
      const allDescendentProposals = getAllDescendantProposals(
        proposalsMap,
        toRelationsMap,
        contentItem.$$meta.permalink
      );
      if (allDescendentProposals.length) {
        return getTransformedProposalWithChildProposalInfo(
          proposal,
          proposal
            ? getProposalType(contentItem, proposal, proposedHrefsToDelete)
            : 'CHILDREN_HAVE_PROPOSALS',
          allDescendentProposals,
          personsMap,
          userHref
        );
      }
    }
    if (!proposal) {
      return null;
    }
    const proposalType = getProposalType(contentItem, proposal, proposedHrefsToDelete);
    return transformProposal(proposal, personsMap, userHref, 0, proposalType);
  }
);

export const selectProposalMetaInfoForToc = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state) => selectProposalsForContentMap(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state, href: ContentHref) => selectHiddenDescendants(state, href),
    selectProposedContentHrefsToDelete,
    (state) => state.externalData.data[pathMap.persons].items,
    (state) => selectUserHref(state),
    (state, href: ContentHref) => selectTableOfContentChildren(state, href),
    (state, href: ContentHref) => href,
    (state) => state.documentUI.mode,
  ],
  (
    contentItem,
    rawContentItem,
    proposalsMap,
    toRelationsMap,
    hiddenDescendants,
    proposedHrefsToDelete,
    personsMap,
    userHref,
    tocChildHrefs,
    href,
    mode
  ): ProposalMetaInfo | null => {
    if (!userHref) {
      return null;
    }

    if (mode === 'EDIT') {
      return null;
      // let proposalType: ProposalType = 'UPDATE';

      // if (contentItem.$$meta.deleted) {
      //   proposalType = 'DELETE';
      // } else if (!rawContentItem) {
      //   proposalType = 'CREATE';
      // }

      // if (contentItem === rawContentItem) {
      //   return null;
      // }

      // return transformProposal(null, personsMap, userHref, 0, proposalType);
    }

    const proposal = getProposalForNode(href, proposalsMap, contentItem, hiddenDescendants);
    const allDescendentProposals = getAllDescendantProposals(
      proposalsMap,
      toRelationsMap,
      href,
      tocChildHrefs
    );
    if (allDescendentProposals.length) {
      // toc row needs to be an indicator if it has children that are not in the toc and have proposals
      // it is not a merge in the same way as with the hidden proposals.
      // We just say there are proposals for the children but we do not specify the changes
      return getTransformedProposalWithChildProposalInfo(
        proposal,
        proposal
          ? getProposalType(contentItem, proposal, proposedHrefsToDelete)
          : 'CHILDREN_HAVE_PROPOSALS',
        allDescendentProposals,
        personsMap,
        userHref
      );
    }
    if (!proposal) {
      return null;
    }
    const proposalType = getProposalType(contentItem, proposal, proposedHrefsToDelete);
    return transformProposal(proposal, personsMap, userHref, 0, proposalType);
  }
);

export const selectProposalMetaInfoAside = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state) => selectProposalsForContentMap(state),
    (state, href: ContentHref) => selectHiddenDescendants(state, href),
    (state) => state.externalData.data[pathMap.persons].items,
    (state) => selectUserHref(state),
    (state, href: ContentHref) => href,
    (state) => state.documentUI.mode,
  ],
  (
    contentItem,
    rawContentItem,
    proposalsMap,
    hiddenDescendants,
    personsMap,
    userHref,
    href,
    mode
  ): ProposalMetaInfo | null => {
    if (!userHref) {
      return null;
    }

    let proposalType: ProposalType = 'UPDATE';

    if (contentItem.$$meta.deleted) {
      proposalType = 'DELETE';
    } else if (!rawContentItem) {
      proposalType = 'CREATE';
    }

    if (mode === 'EDIT') {
      return transformProposal(null, personsMap, userHref, 0, proposalType);
    }

    const proposal = getProposalForNode(href, proposalsMap, contentItem, hiddenDescendants);

    if (!proposal) {
      return null;
    }

    return transformProposal(proposal, personsMap, userHref, 0, proposalType);
  }
);

export const selectNodeProposal = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state) => selectProposalsForContentMap(state),
    (state, href: ContentHref) => selectHiddenDescendants(state, href),
    (state, href: ContentHref) => href,
  ],
  (content, proposalsMap, hiddenDescendants, href): Proposal | null => {
    return getProposalForNode(href, proposalsMap, content, hiddenDescendants);
  }
);

export const selectProposalDiffMessages = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state) => selectDocumentRootType(state),
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
    (state) => state.documentApi.content,
    (state) => selectProposalsForContentMap(state),
    (state, href: ContentHref) => selectHiddenDescendants(state, href),
    selectProposedContentHrefsToDelete,
    (state) => selectAllExternalData(state),
    (state) => selectApiWithPendingChanges(state).relationsMap,
    (state) => state.documentUI.mode,
  ],
  (
    contentItem,
    documentType,
    nodeTypeConfig,
    rawContentMap,
    proposalsMap,
    hiddenDescendants,
    proposedHrefsToDelete,
    externalData,
    relationsMap,
    mode
  ): Array<DiffMessage> => {
    if (mode === 'EDIT' || !documentType) {
      return emptyArray;
    }

    return getDiffTextForNode(
      proposalsMap,
      contentItem,
      rawContentMap,
      hiddenDescendants,
      proposedHrefsToDelete,
      nodeTypeConfig,
      externalData,
      relationsMap
    );
  }
);

export const selectChangeMessageForHtmlString = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href: ContentHref) => selectRawContentItem(state, href),
    (state) => state.documentUI.mode,
    (state, href: ContentHref, config: EditComponent) => config,
  ],
  (content, originalContent, mode, config): string | null => {
    if (mode === 'EDIT') {
      return null;
    }

    if (!config.property) {
      console.error('No property found for config', config);
      throw Error(`Can not convert ${config.property} to a string`);
    }

    const newValue = content ? content[config.property] : '';
    const oldValue = originalContent ? originalContent[config.property] : '';

    if (newValue === oldValue) {
      return null;
    }

    const diffHtml = htmlDiffFunction(newValue, oldValue);

    return diffHtml;
  }
);
