import React from 'react';
import { Provider } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ThemeProvider } from '@emotion/react';
import { theme } from '@UI/muiTheme';
import { getStore } from './store';

const StoreProvider = ({ children }) => {
  const store = getStore();
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default StoreProvider;
