import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectAllExternalData,
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { getRoot } from '@newStore/externalData/externalDataHelpers';
import constants from '@store/constants/constants';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';
import {
  Content,
  ContentHref,
  ExternalUrl,
  InvalidExternalUrl,
  isContentHref,
} from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { isTrainingModule, TrainingModule } from '@generalTypes/trainingTypes';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { getThumbnail } from './helpers';
import { LinkedContent, TeaserNode } from '../documentUITypes';

const selectReferenceChildHref = (state: RootState, href: ContentHref) => {
  const childHref = selectApiWithPendingChangesRelationsToAndFromMap(state).to[href]?.find(
    (z) => z.relationtype === 'IS_PART_OF'
  )?.from.href;
  if (!childHref) {
    return undefined;
  }
  return childHref;
};

export const selectReferenceChild = (state: RootState, href: ContentHref) => {
  const childHref = selectReferenceChildHref(state, href);
  if (!childHref) {
    return undefined;
  }
  return selectApiWithPendingChanges(state).content[childHref];
};

export const selectReferencesRelation = (state: RootState, href: ContentHref) => {
  const childHref = selectReferenceChildHref(state, href);
  if (!childHref) {
    return undefined;
  }
  const relations = selectApiWithPendingChangesRelationsToAndFromMap(state).from[childHref];
  const referencesRelation = relations?.find((z) => z.relationtype === 'REFERENCES');
  return referencesRelation;
};

const selectExternalNodeForCallToAction = (state: RootState, href: ContentHref) => {
  const relation = selectReferencesRelation(state, href);
  if (!relation) {
    return undefined;
  }
  return (selectAllExternalData(state)[relation.to.href] || null) as
    | Content
    | TrainingModule
    | null;
};

/**
 * this selector retuns linkedContent in a format that the aside expects it.
 * it does however contain too much data for the main screen (left side)
 */
export const selectLinkedContent = createTypedSelector(
  [
    (state, href: ContentHref) => selectReferenceChild(state, href),
    (state, href: ContentHref) => selectReferencesRelation(state, href),
    (state, href: ContentHref) => selectExternalNodeForCallToAction(state, href),
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
  ],
  (reference, referencesRelation, resource, externalContent, externalRelationsMap) => {
    if (!reference || !referencesRelation) {
      return {
        callToAction: reference?.title || 'Lees meer',
        type: constants.teaserLinkOptions.NOT_LINKED.name,
      };
    }
    const callToActionHref = referencesRelation?.to.href;

    // EXTERNAL_URL
    if (
      !resource &&
      !isContentHref(referencesRelation.to.href) &&
      !referencesRelation.to.href.startsWith('/training')
    ) {
      return {
        referenceKey: reference.key,
        referencedResourceHref: referencesRelation.to.href,
        callToAction: reference.title,
        type: constants.teaserLinkOptions.EXTERNAL_URL.name,
        title: referencesRelation.to.href,
      };
    }

    if (!resource) {
      return null;
    }

    if (isTrainingModule(resource)) {
      return {
        referenceKey: reference.key,
        referencedResourceHref: resource.$$meta.permalink,
        referencedResource: resource,
        callToAction: reference.title,
        type: constants.teaserLinkOptions.EVENT.name,
        title: resource.title,
      };
    }

    // JOB_OFFER
    if (resource.tags && ['JOB_OFFER', 'PRONEWSITEM'].includes(resource.tags[0])) {
      return {
        referenceKey: reference.key,
        referencedResourceKey: resource.key,
        referencedResourceHref: resource.$$meta.permalink,
        referencedResource: resource,
        callToAction: reference.title,
        type: constants.teaserLinkOptions[resource.tags[0]].name,
        title: resource.title,
      };
    }

    // SHARED_MINI_DATABASE_ITEM
    if (resource.tags && ['SHARED_MINI_DATABASE_ITEM'].includes(resource.tags[0])) {
      return {
        referenceKey: reference.key,
        referencedResourceKey: resource.key,
        referencedResourceHref: resource.$$meta.permalink,
        referencedResource: resource,
        callToAction: reference.title,
        type: constants.teaserLinkOptions[resource.tags[0]].name,
        title: resource.title,
      };
    }

    // WEBPAGE2
    if (['STRUCTURED_DOCUMENT', 'SECTION'].includes(resource.type)) {
      const root = getRoot(externalRelationsMap, externalContent, callToActionHref);
      return {
        referenceKey: reference.key,
        referencedResourceKey: root ? root.key : resource.key,
        referencedResourceHref: resource.$$meta.permalink,
        referencedResourceRoot: root || resource,
        referencedResource: root && root !== resource ? resource : undefined,
        callToAction: reference.title,
        type: constants.teaserLinkOptions.WEBPAGE2.name,
        title: root ? `${root.title} - ${resource.title}` : resource.title,
        filter: {
          typeIn: 'SECTION',
          root: root ? root.$$meta.permalink : resource.$$meta.permalink,
          $$expandPathToRoot: true,
        },
      };
    }

    return null;
  }
);

export const selectTeaserNode = createTypedSelector(
  [
    (state, href: ContentHref) => selectApiWithPendingChanges(state).content[href],
    (state, href: ContentHref) => selectLinkedContent(state, href),
    (state, href: ContentHref) => selectExternalNodeForCallToAction(state, href) === null,
  ],
  (content: Content, linkedContentForAside, linkedContentLoading): TeaserNode => {
    const linkOptions = constants.teaserLinkOptions;

    const thumbnail = getThumbnail(content.attachments);

    let linkedContent: LinkedContent | null = null;
    if (linkedContentForAside && linkedContentForAside.type !== linkOptions.NOT_LINKED.name) {
      linkedContent = { callToAction: linkedContentForAside.callToAction as string, title: '' };

      // eslint-disable-next-line default-case
      switch (linkedContentForAside.type) {
        case linkOptions.EXTERNAL_URL.name:
          linkedContent.title = `Gelinkt aan url: ${linkedContentForAside.title}`;
          linkedContent.url = linkedContentForAside.referencedResourceHref as
            | ExternalUrl
            | InvalidExternalUrl;
          break;
        case linkOptions.EVENT.name:
          linkedContent.title = `Gelinkt event: ${linkedContentForAside.title}`;
          linkedContent.url = `https://nascholing.be/redirectTo.aspx?redirectID=${linkedContentForAside.referencedResourceHref}`;
          break;
        case linkOptions.JOB_OFFER.name:
          linkedContent.title = `Gelinkte vacature: ${linkedContentForAside.title}`;
          break;
        case linkOptions.SHARED_MINI_DATABASE_ITEM.name:
          linkedContent.title = `Gelinkt aan gedeeld databankitem: ${linkedContentForAside.title}`;
          break;
        case linkOptions.WEBPAGE2.name:
          linkedContent.title = `Gelinkt aan pagina: ${linkedContentForAside.title}`;
          break;
        case linkOptions.PRONEWSITEM.name:
          linkedContent.title = `Gelinkt nieuwsbericht: ${linkedContentForAside.title}`;
          break;
      }

      if (!linkedContent.url) {
        linkedContent.documentKey = linkedContentForAside.referencedResourceKey;
      }
    }

    const isExternalLink = linkedContentForAside?.type === linkOptions.EXTERNAL_URL.name;

    const teaser: TeaserNode = {
      href: content.$$meta.permalink,
      key: content.key,
      title: content?.title || '',
      description: content.description || '',
      type: NodeType.TEASER,
      image: thumbnail ? { url: getAttachmentUrl(thumbnail) } : null,
      customRender: true,
      linkedContent,
      linkedContentLoading: !isExternalLink && linkedContentLoading,
    };
    return teaser;
  }
);
