import { ContentHref } from '@generalTypes/apiTypes';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { EditAsideDescription } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import AsideEditor from '@UI/editor/AsideEditor';
import { any } from 'prop-types';
import React from 'react';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideDescription: React.FC<{
  config: EditAsideDescription;
}> = ({ config }) => {
  const editHref = useEditHref();

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const label = config.fullLabel ?? config.label;

  const changeMessage = useTypedSelector((state) =>
    selectChangeMessageForHtmlString(
      state,
      state.documentUI.currentEditingNode as ContentHref,
      config
    )
  );

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator htmlChangeMessage={changeMessage} />
        {label && <label className="control-label control-label-inline">{label}</label>}
        <AsideEditor
          placeholder={''}
          skey={getResourceKey(editHref)}
          initial={initialValue}
          field={config.property}
          readOnly={readOnly}
          plainText={false}
          inline={false}
          showCharacterCount={true}
        ></AsideEditor>
      </AsideValidation>
    </div>
  );
};

AsideDescription.displayName = 'AsideDescription';

export default AsideDescription;

AsideDescription.propTypes = {
  config: any,
};
