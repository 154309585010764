import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import {
  authorsComponent,
  publishComponent,
  titleComponent,
} from '../../helpers/genericComponents';
import { proThemeAudienceTab } from '../proGenericConfigProps';
// Extends WEBPAGE2 => When adding GLOBAL_TRAININGSDATABASE WebConfig to a WEBPAGE2 it becomes "the" (there is only one) PRO_GLOBAL_DATABASE
export const PRO_GLOBAL_DATABASE: ExtendedRootNodeConfig = {
  information: {
    description: 'Pro Global database is just one page: the search page for all trainings',
    single: 'Globale professionaliseringsdatabank',
    plural: 'Globale professionaliseringsdatabank',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: [],
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      property: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      label: 'Themaverantwoordelijke',
    },
    publishComponent,
  ],
  audienceTab: proThemeAudienceTab,
};
