import { ProposalMetaInfo } from '@newStore/documentUI/documentUITypes';

import './ProposalIndicator.scss';
import { RootState } from '@generalTypes/rootStateTypes';
import { useSelector } from 'react-redux';
import { ContentHref } from '@generalTypes/apiTypes';
import { useState } from 'react';
import ProposalDiffMessage from './ProposalDiffMessage';
import { getBorderLeftColor, getUserColor } from './ProposalIndicatorHelpers';

const ProposalIndicator: React.FC<{
  href: ContentHref;
  proposalMetaInfo: ProposalMetaInfo;
}> = ({ href, proposalMetaInfo }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mode = useSelector((state: RootState) => state.documentUI.mode);
  const borderLeftColor = getBorderLeftColor(proposalMetaInfo, mode);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="redactie-proposal-indicator">
      <div
        className="proposal-line-container"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div
          className="line"
          // style={proposalLineStyle}
          style={{
            borderLeftStyle:
              proposalMetaInfo.status === 'SUBMITTED_FOR_REVIEW' ? 'solid' : 'dashed',
            borderLeftColor,
            borderRight: proposalMetaInfo.authors.length > 1 ? `2px solid #3499eb` : undefined,
            paddingRight: proposalMetaInfo.authors.length > 1 ? '2px' : '4px',
          }}
        ></div>
      </div>
      {anchorEl && (
        <ProposalDiffMessage
          href={href}
          proposalMetaInfo={proposalMetaInfo}
          userColor={getUserColor(proposalMetaInfo)}
          anchorEl={anchorEl}
        />
      )}
    </div>
  );
};

export default ProposalIndicator;
