// DO NOT CHANGE ORDER OF IMPORTS, tests might break
import { combineReducers } from 'redux-loop';
import newDocumentListState from '@newStore/documentList/newDocumentListState';
import newUserState from '@newStore/user/userState';
import externalDataState from '@newStore/externalData/externalDataState';
import documentApiReducer from '@newStore/documentApi/documentApiState';
import documentUIReducer from '@newStore/documentUI/documentUIState';
import referenceFramesReducer from '@newStore/referenceFrames/referenceFramesState';
import validationDataReducer from '@newStore/validation/validationState';
import versionsReducer from '@newStore/externalData/versionsState';
import { notificationReducer } from './notificationsReducer';
import { documentDragReducer } from './documentDragReducer';
import { documentReducer } from './documentReducer';

import { reduxLoopUtilsReducer } from './reduxLoopUtilsReducer';

const rawStateReducer = combineReducers({
  // typescript (obviously) freaks out on the old reducers
  // @ts-ignore
  document: documentReducer,
  // @ts-ignore
  documentDrag: documentDragReducer,
  // @ts-ignore
  reduxLoopUtils: reduxLoopUtilsReducer,
  // @ts-ignore
  notifications: notificationReducer,
  documentApi: documentApiReducer,
  documentUI: documentUIReducer,
  referenceFrames: referenceFramesReducer,
  newDocumentList: newDocumentListState.reducer,
  newUser: newUserState.reducer,
  externalData: externalDataState.reducer,
  // @ts-expect-error no clue WHY it is not working
  validationData: validationDataReducer,
  versions: versionsReducer.reducer,
});

export const rootReducer = (state: any, action: any) => {
  // console.group(action.type);
  console.info('ACTION', action.type, action);
  // console.time(action.type);
  const newLoop = rawStateReducer(state, action, state);
  // console.log('STATE', newLoop[0]);
  // console.log('VIEWMODEL', newLoop[0].document.viewModel);
  // console.timeEnd(action.type);
  // console.groupEnd();
  return newLoop;
};
