import React from 'react';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { EditAsideAttachedContent } from '@nodeTypeConfig/configTypes';
import AsideEditor from '@UI/editor/AsideEditor';
import { any } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import { ContentHref } from '@generalTypes/apiTypes';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideAttachedContent: React.FC<{
  config: EditAsideAttachedContent;
}> = ({ config }) => {
  const editHref = useEditHref();
  const nodeKey = getResourceKey(editHref);

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const changeMessage = useSelector((state: RootState) =>
    selectChangeMessageForHtmlString(
      state,
      state.documentUI.currentEditingNode as ContentHref,
      config
    )
  );

  return (
    <AsideValidation property={config.property} component={config.component}>
      <div className={`form-group ${readOnly ? 'aside-component-disabled' : ''}`} id="content">
        <AsideChangeIndicator htmlChangeMessage={changeMessage} />
        <label className="control-label">{config.label}</label>
        <AsideEditor
          placeholder={`Vul een ${config.label.toLowerCase()} in...`}
          initial={initialValue}
          field="html"
          skey={nodeKey}
          readOnly={readOnly}
          showCharacterCount={true}
        />
      </div>
    </AsideValidation>
  );
};

export default AsideAttachedContent;

AsideAttachedContent.displayName = 'AsideAttachedContent';

AsideAttachedContent.propTypes = {
  config: any,
};
