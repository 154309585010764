import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    htmlFontSize: 11.5, // dupe MUI to set default font size to 14px
    fontSize: 14, // Set the default font size to 14px
  },
  //   components: {
  //     MuiCssBaseline: {
  //       styleOverrides: {
  //         html: {
  //           fontSize: '62.5%', // 62.5% of 16px = 10px
  //           //   fontSize: '71.4%',
  //         },
  //         body: {
  //           fontSize: '1.4rem', // 1.4rem * 10px = 14px
  //         },
  //       },
  //     },
  //   },
});
