import { Modal } from '@kathondvla/react-shared-components/src/components';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { EditAsideLinks } from '@nodeTypeConfig/configTypes';
import {
  addLinkReferenceNodeAction,
  editLinkReferenceNodeAction,
  removeNodeAction,
} from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import LinkModal from '@UI/modals/LinkModal';
import ModalErrorBoundary from '@UI/modals/ModalErrorBoundary';
import { any } from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import './asideLinks.scss';
import { selectAsideLinks, selectChangeMessageForLinks } from './aslideLinksSelectors';

export type Link = {
  title: string;
  url: string;
};

const AsideLinks: React.FC<{
  config: EditAsideLinks;
}> = ({ config }) => {
  const editHref = useEditHref();
  const nodeKey = getResourceKey(editHref);

  const dispatch = useDispatch();
  const confirmationService = getAngularService('ConfirmationService');
  const links = useTypedSelector((state) => selectAsideLinks(state, editHref));
  const htmlDiffMessage = useTypedSelector((state) =>
    selectChangeMessageForLinks(state, editHref, config)
  );
  const readOnly = useReadOnly(false);
  const linkModalRef = useRef<Modal>();

  const addLink = async () => {
    linkModalRef.current?.open({
      modalTitle: config.options?.modalTitle,
      onSubmit: async (link: Link) => {
        if (link) {
          dispatch(
            addLinkReferenceNodeAction(
              nodeKey,
              link.title,
              link.url,
              config.options?.type === 'group'
            )
          );
        }
      },
    });
  };

  const editLink = async (item) => {
    linkModalRef.current?.open({
      link: { title: item.title, url: item.href },
      modalTitle: config.options?.modalTitle || 'Link bewerken',
      onSubmit: async (link: Link) => {
        if (link) {
          dispatch(editLinkReferenceNodeAction(nodeKey, item.key, link.title, link.url));
        }
      },
    });
  };

  const deleteLink = async (link) => {
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(removeNodeAction(link.key, true, nodeKey));
    }
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideChangeIndicator htmlChangeMessage={htmlDiffMessage}></AsideChangeIndicator>
      <div className="panel panel-default">
        <div className="panel-heading">
          <div className="panel-controls panel-controls-left">
            <h3 className="panel-title">{config.label}</h3>
          </div>
          <div className="panel-controls panel-controls-right">
            <div className="btn-toolbar">
              {!readOnly && (
                <button className="btn btn-default" onClick={addLink}>
                  <span className="glyphicon glyphicon-add"></span>
                  <span>Voeg toe</span>
                </button>
              )}
            </div>
          </div>
        </div>

        {links.length !== 0 && (
          <div className="structure">
            {links.map((link) => (
              <div className="row table-row" key={link.key}>
                <>
                  <div
                    className={`col-md-12 link-row ${
                      link.isDeletedByProposal ? 'deleted-hidden-child' : ''
                    }`}
                  >
                    <div>
                      <a href={link.href} target="_blank" rel="noreferrer">
                        {link.href}
                      </a>
                      <br />
                      {link.title}
                    </div>
                  </div>
                  {!readOnly && !link.isDeletedByProposal && (
                    <div
                      className="btn-group btn-group-xs row-actions"
                      role="group"
                      aria-label="..."
                    >
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => editLink(link)}
                      >
                        <span className="glyphicon glyphicon-edit"></span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => deleteLink(link)}
                      >
                        <span className="glyphicon glyphicon-delete"></span>
                      </button>
                    </div>
                  )}
                </>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal
        ref={linkModalRef}
        size="sm"
        component={<LinkModal />}
        errorBoundaryComponent={ModalErrorBoundary}
      />
    </div>
  );
};

AsideLinks.displayName = 'AsideLinks';

export default AsideLinks;

AsideLinks.propTypes = {
  config: any,
};
