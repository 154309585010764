import React, { useState } from 'react';

import { Modal } from '@kathondvla/react-shared-components/src/components';
import { Link } from '@UI/aside/asideLinks/AsideLinks';

const hasEmptyUrl = (url: string) => !url.trim();
const isExternalLinkInvalid = (url: string) => {
  // This is what we had in the angular component, but I guess we could do better wit a regexp
  return Boolean(url.trim() && !(url.startsWith('https://') || url.startsWith('http://')));
};

export interface LinkModalProps {
  modalTitle?: string;
  link?: Link;
  onClose?: () => void;
  onSubmit?: (link: { title: string; url: string }) => void;
}

const LinkModal: React.FC<LinkModalProps> = ({
  modalTitle = '',
  link = { title: '', url: '' },
  onClose = () => {},
  onSubmit,
}) => {
  const [linkForm, setLinkForm] = useState(link);
  const [errors, setErrors] = useState({ emptyUrl: false, invalidUrl: false });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLinkForm((prevLink) => ({ ...prevLink, [name]: value }));

    if (name === 'url') {
      validate(value);
    }
  };

  const handleSubmit = () => {
    const isValid = validate(linkForm.url);

    if (onSubmit && isValid) {
      onSubmit(linkForm);
      onClose();
    }
  };

  const validate = (url: string) => {
    const validationErrors = { emptyUrl: hasEmptyUrl(url), invalidUrl: isExternalLinkInvalid(url) };
    setErrors(validationErrors);
    return !validationErrors.emptyUrl && !validationErrors.invalidUrl;
  };

  return (
    <>
      <Modal.Header title={modalTitle || 'Link invoegen'} onClose={onClose} />
      <Modal.Body>
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <label>Tekst van link</label>
              <input
                type="text"
                name="title"
                value={linkForm.title}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <label>Naar welke URL moet deze link verwijzen?</label>
              <input
                type="text"
                name="url"
                value={linkForm.url}
                onChange={handleChange}
                className="form-control"
              />
              {errors.emptyUrl && <small className="label-error">URL moet ingevuld zijn.</small>}
              {linkForm.url && errors.invalidUrl && (
                <small className="label-error">De url moet beginnen met https:// of http://</small>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-link pull-left" onClick={onClose}>
          Annuleren
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Ok
        </button>
      </Modal.Footer>
    </>
  );
};

LinkModal.displayName = 'displayName';

export default LinkModal;
