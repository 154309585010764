import { IApiResouce } from '@generalTypes/sriTypes';
import { Content, ContentRelation, WebSite, WebTemplate, NamedSet } from '@generalTypes/apiTypes';
import { ApiEvent } from '@generalTypes/eventTypes';
import { NewsletterSender } from '@generalTypes/newsletterTypes';
import { Person } from '@generalTypes/personApiTypes';
import {
  EducationalActivityType,
  OrganisationalUnit,
  SecundaryEducationType,
  Studiegebied,
  StudyProgramme,
  StudyProgrammeGroup,
  Subject,
  Vak,
  VosOrganisationalUnit,
} from '@generalTypes/samTypes';
import { TrainingModule } from '@generalTypes/trainingTypes';

export const pathMap = {
  persons: '/persons',
  ous: '/sam/organisationalunits',
  vosOus: '/organisations',
  webSites: '/web/sites',
  webTemplates: '/web/templates',
  newsletterSenders: '/newsletter/senders',
  subjects: '/sam/commons/subjects',
  studyProgrammes: '/sam/commons/studyprogrammes',
  studyProgrammeGroups: '/sam/commons/studyprogrammegroups',
  studiegebieden: '/sam/commons/studiegebieden',
  vakken: '/vakken',
  events: '/events',
  content: '/content',
  contentRelations: '/content/relations',
  trainingModules: '/training/modules',
  educationalActivityTypes: '/sam/commons/educationalactivitytypes',
  secondaryEducationTypes: '/sam/commons/secondaryeducationtypes',
  namedSets: '/namedsets',
  others: 'others',
} as const;

export type PathMap = typeof pathMap;

type ExternalDataType<T> = Readonly<{
  items: Readonly<Record<string, T>>;
  isLoading?: boolean;
}>;

type ExternalDataStateBase = {
  resourcesToLoad: ReadonlyArray<string>;
  failedResources: ReadonlyArray<string>;
  resourceAncestorsToLoad: ReadonlyArray<string>;
  failedResourceAncestors: ReadonlyArray<string>;
  loadedAncestors: ReadonlyArray<string>;
  data: Readonly<{
    [pathMap.persons]: ExternalDataType<Person>;
    [pathMap.ous]: ExternalDataType<OrganisationalUnit>;
    [pathMap.vosOus]: ExternalDataType<VosOrganisationalUnit>;
    [pathMap.webSites]: ExternalDataType<WebSite>;
    [pathMap.webTemplates]: ExternalDataType<WebTemplate>;
    [pathMap.newsletterSenders]: ExternalDataType<NewsletterSender>;
    [pathMap.subjects]: ExternalDataType<Subject>;
    [pathMap.studyProgrammes]: ExternalDataType<StudyProgramme>;
    [pathMap.studyProgrammeGroups]: ExternalDataType<StudyProgrammeGroup>;
    [pathMap.studiegebieden]: ExternalDataType<Studiegebied>;
    [pathMap.vakken]: ExternalDataType<Vak>; // this is wrong! Commons from vos have a name and no title
    [pathMap.events]: ExternalDataType<ApiEvent>;
    [pathMap.content]: ExternalDataType<Content>;
    [pathMap.contentRelations]: ExternalDataType<ContentRelation>;
    [pathMap.trainingModules]: ExternalDataType<TrainingModule>;
    [pathMap.educationalActivityTypes]: ExternalDataType<EducationalActivityType>;
    [pathMap.secondaryEducationTypes]: ExternalDataType<SecundaryEducationType>;
    [pathMap.namedSets]: ExternalDataType<NamedSet>;
    [pathMap.others]: ExternalDataType<IApiResouce>;
  }>;
};

export type ExternalDataState = Readonly<ExternalDataStateBase>;

export type ExternalResource =
  ExternalDataStateBase['data'][keyof ExternalDataStateBase['data']] extends ExternalDataType<
    infer T
  >
    ? T
    : never;

export type AllExternalData = Record<string, ExternalResource>;
