import {
  Content,
  ContentHref,
  ContentRelation,
  ContentRelationHref,
  ContentType,
  PersonHref,
  Proposal,
} from '@generalTypes/apiTypes';
import { Person } from '@generalTypes/personApiTypes';
import { AllExternalData } from '@newStore/externalData/externalDataTypes';
import { formatDate, isNotNull } from '@newStore/genericHelpers';
import { NodeTypeConfigApplied, isHiddenChildrenComponent } from '@nodeTypeConfig/configTypes';
import { getAllDescendantHrefs } from '../documentUIHelpers';
import { DiffMessage, ProposalMetaInfo, ProposalType } from '../documentUITypes';
import { getDiffMessages } from './diffTextCalculator';
import {
  HiddenDescendantWithProposal,
  getDiffMessagesForHiddenDescendants,
} from './hiddenChildrenDiffTextCalculator';

/**
 * Gathers all proposals for any descendent (including deleted descendants) not mentioned in excludeHrefs or below that.
 */
export const getAllDescendantProposals = (
  proposalsMap: Record<ContentHref, Proposal>,
  toRelationsMap: Record<string, Array<ContentRelation>>,
  href: ContentHref,
  excludeHrefs?: ContentHref[]
): Proposal[] => {
  const descendantHrefs = getAllDescendantHrefs(toRelationsMap, href, new Set(excludeHrefs));
  return descendantHrefs.map((dHref) => proposalsMap[dHref]).filter(isNotNull);
};

export const mergeProposals = (proposals: Proposal[]): Proposal | null => {
  if (!proposals.length) {
    return null;
  }
  return proposals.reduce((mergedProposal, proposal) => ({
    ...mergedProposal,
    $$meta: {
      ...mergedProposal.$$meta,
      modified:
        proposal.status === 'SUBMITTED_FOR_REVIEW' &&
        proposal.$$meta.modified > mergedProposal.$$meta.modified
          ? proposal.$$meta.modified
          : mergedProposal.$$meta.modified,
    },
    creators: [...new Set([...mergedProposal.creators, ...proposal.creators])],
    status:
      mergedProposal.status === 'SUBMITTED_FOR_REVIEW' || proposal.status === 'SUBMITTED_FOR_REVIEW'
        ? 'SUBMITTED_FOR_REVIEW'
        : 'IN_PROGRESS',
    listOfRequestedChanges: [
      ...mergedProposal.listOfRequestedChanges,
      ...proposal.listOfRequestedChanges,
    ],
  }));
};

/* const mergeDiffText = (elems: Array<DiffTextInput>): Array<DiffMessage> =>
  elems.reduce((allDiffMsgs, calcInput) => {
    return [...allDiffMsgs, ...getDiffMessages(calcInput)];
  }, [] as Array<DiffMessage>); */

/**
 * returns the proposal and the diff text for the content node which is not the same as get the proposal for the content item
 * When a content item has hidden children, the children become part of the node
 * Therefore the proposal must integrate the proposals on the children as part of the proposal on this node
 */
export const getProposalForNode = (
  href: ContentHref,
  proposalsMap: Record<ContentHref, Proposal>,
  content: Content,
  hiddenDescendants: Content[]
) => {
  if (!hiddenDescendants.length) {
    return proposalsMap[href] || null;
  }
  // use case for node with hiddenDesendants they become part of the same node and so the proposal needs to be merged.
  const nodeProposals = [content, ...hiddenDescendants].flatMap((contentItem) => {
    const proposal = proposalsMap[contentItem.$$meta.permalink];
    return proposal || [];
  });
  const mergedProposal = mergeProposals(nodeProposals);
  return mergedProposal;
};

/**
 * returns the proposal and the diff text for the content node which is not the same as get the proposal for the content item
 * When a content item has hidden children, the children become part of the node
 * Therefore the proposal must integrate the proposals on the children as part of the proposal on this node
 */
export const getDiffTextForNode = (
  proposalsMap: Record<ContentHref, Proposal>,
  contentItem: Content,
  rawContentMap: Record<string, Content>,
  hiddenDescendants: Content[],
  proposedHrefsToDelete: Array<ContentHref>,
  nodeTypeConfig: NodeTypeConfigApplied,
  externalData: AllExternalData,
  relationsMap: Record<ContentRelationHref, ContentRelation>
) => {
  const proposal = proposalsMap[contentItem.$$meta.permalink];
  const diffMsgsForContentItem = proposal
    ? getDiffMessages({
        href: contentItem.$$meta.permalink,
        proposal,
        content: contentItem,
        rawContent: rawContentMap[contentItem.$$meta.permalink],
        proposedHrefsToDelete,
        nodeTypeConfig, // hidden children never have a webpage
        externalData,
        relationsMap,
      })
    : [];
  if (hiddenDescendants.length === 0) {
    // console.log(
    //   `messages for ${contentItem.$$meta.permalink}${
    //     contentItem.title ? ` ${contentItem.title}` : ''
    //   }`,
    //   diffMsgsForContentItem
    // );
    return diffMsgsForContentItem;
  }
  const componentsForHiddenChildren = nodeTypeConfig.edit.filter(isHiddenChildrenComponent);

  // group all hiddenChildren with a proposal per component for hidden children
  // if no component can be found group them per child.type
  const hiddenDescendantsWithProposalPerType: Partial<
    Record<ContentType, Array<HiddenDescendantWithProposal>>
  > = {};
  hiddenDescendants.forEach((c) => {
    const proposalForDescendant = proposalsMap[c.$$meta.permalink];
    if (!proposalForDescendant) {
      return;
    }
    const component = componentsForHiddenChildren.find(
      (comp) =>
        comp.hiddenChildren.containerType === c.type ||
        comp.hiddenChildren.items.some((i) => i.type === c.type)
    );
    const emptyArray: Array<HiddenDescendantWithProposal> = [];
    const contentTypeKey = component?.hiddenChildren.containerType || c.type;
    const hdsForType = hiddenDescendantsWithProposalPerType[contentTypeKey] || emptyArray;
    if (!hiddenDescendantsWithProposalPerType[contentTypeKey]) {
      hiddenDescendantsWithProposalPerType[contentTypeKey] = emptyArray;
    }
    hdsForType.push({
      content: c,
      proposal: proposalForDescendant,
      rawContent: rawContentMap[c.$$meta.permalink],
    });
  });
  const diffMessagesForHiddenDescendants = Object.entries(
    hiddenDescendantsWithProposalPerType
  ).flatMap(([cType, hdmInput]) => {
    const msg = getDiffMessagesForHiddenDescendants(
      hdmInput as HiddenDescendantWithProposal[],
      componentsForHiddenChildren.find((c) => c.hiddenChildren.containerType === cType),
      proposedHrefsToDelete
    );
    return msg || [];
  });
  // console.log(
  //   `messages for ${contentItem.$$meta.permalink}${
  //     contentItem.title ? ` ${contentItem.title}` : ''
  //   }`,
  //   [...diffMsgsForContentItem, ...diffMessagesForHiddenDescendants]
  // );
  return [...diffMsgsForContentItem, ...diffMessagesForHiddenDescendants];
};

const getChildNodeProposalsText = (nbrOfChildProposals: number): DiffMessage => {
  // the number could be a lie if there ar proposals of "hidden content items" which belong to their parent node but it's close enough
  return {
    message: `Er ${nbrOfChildProposals > 1 ? 'zijn' : 'is'} ${nbrOfChildProposals} suggestie${
      nbrOfChildProposals > 1 ? 's' : ''
    } voor de onderliggende inhoud`,
  };
};

export const getAuthorsDescription = (authors: Person[], userHref: string | null) =>
  authors
    .map((p) => (p.$$meta.permalink === userHref ? 'jezelf' : `${p.firstName} ${p.lastName}`))
    .join(', ')
    .replace(/,(?=[^,]+$)/, ' &');

export const transformProposal = (
  proposal: Proposal | null,
  personsMap: Record<string, Person>,
  userHref: PersonHref,
  nbOfChildProposals: number,
  type: ProposalType
): ProposalMetaInfo => {
  const authors =
    proposal === null
      ? [personsMap[userHref]]
      : proposal.creators.map((pHref) => personsMap[pHref]).filter((z) => z);
  return {
    href: proposal?.$$meta.permalink || null,
    status: proposal?.status || 'IN_PROGRESS',
    authors: authors.map((p) => ({
      href: p.$$meta.permalink,
      firstName: p.firstName,
      lastName: p.lastName,
    })),
    authorsDescription: getAuthorsDescription(authors, userHref),
    userIsAuthor: proposal ? proposal.creators.some((pHref) => pHref === userHref) : true,
    submittedOn:
      proposal?.status === 'SUBMITTED_FOR_REVIEW' ? formatDate(proposal.$$meta.modified) : null,
    childProposalsMessage:
      nbOfChildProposals > 0 ? getChildNodeProposalsText(nbOfChildProposals) : null,
    type,
  };
};

export const wrapNodeTypeLabel = (strings, ...values) => {
  let str = '';

  values.forEach((value, index) => {
    if (index === 0) {
      str += `${strings[index]}<span class="node-type-label">${value}</span>`;
    } else {
      str += `${strings[index]}${value}`;
    }
  });

  str += strings[strings.length - 1];
  return str;
};

export const wrapPropertyLabel = (strings, ...values) => {
  let str = '';

  values.forEach((value, index) => {
    if (index === 0) {
      str += `${strings[index]}<span class="property-label">${value}</span>`;
    } else {
      str += `${strings[index]}${value}`;
    }
  });

  str += strings[strings.length - 1];
  return str;
};
