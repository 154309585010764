import React, { ReactElement } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
    classes={{ popper: className || '' }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#4d4d4d',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '10px',
    backgroundColor: '#4d4d4d',
  },
}));

const CustomTooltip: React.FC<{ name: string; className?: string; children: ReactElement }> = ({
  name,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <StyledTooltip title={name}>{children}</StyledTooltip>
    </div>
  );
};

export default CustomTooltip;
