import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { selectRootHref } from '@newStore/documentUI/documentUISelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { createTypedSelector, mapRelationsToRelationsToAndFrom } from '@newStore/genericHelpers';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';

const getConcordantiesRelationsFromContentHref = (relationsMap, href) => {
  return relationsMap.from[href]?.filter((relation) => relation.relationtype === 'IS_VERSION_OF');
};

export const selectRootOdetHref = createTypedSelector(
  [selectApiWithPendingChangesRelationsToAndFromMap, selectRootHref],
  (relationsMap, rootHref) => {
    const rootConcordantieRelation = getConcordantiesRelationsFromContentHref(
      relationsMap,
      rootHref
    )?.[0];
    return rootConcordantieRelation?.to.href;
  }
);

export const selectRootOdet = (state) => state.referenceFrames?.[selectRootOdetHref(state)];

export const selectAsideConcordanties = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    selectRootOdet,
    (state, href) => href,
  ],
  (relationsMap, rootOdet, href) => {
    const concordantieHrefList = getConcordantiesRelationsFromContentHref(relationsMap, href);
    return concordantieHrefList.flatMap((relation) => {
      const content = rootOdet?.content[relation.to.href];
      if (content && rootOdet) {
        const goalIdentifier = getGoalIdentifier(
          getPathToRoot(
            mapRelationsToRelationsToAndFrom(rootOdet.relations),
            rootOdet.content,
            content.$$meta.permalink
          )
        );
        return {
          key: relation.key,
          title: content.title,
          contentKey: content.key,
          goalIdentifier,
        };
      }
      return {
        key: relation.key,
      };
    });
  }
);
