import { selectApiWithPendingChanges } from '@newStore/documentApi/documentApiSelectors';
import { selectGenericDocumentRootConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector, getResourcePathFromHref, isHref } from '@newStore/genericHelpers';
import { selectAllReferenceFrameData } from '@newStore/referenceFrames/referenceFramesSelectors';
import { PersonHref } from '@generalTypes/apiTypes';
import { OrganisationalUnitHref, VosOrganisationalUnitHref } from '@generalTypes/samTypes';
import { selectAllExternalData } from './externalDataSelectors';
import { isEntityHref } from './externalDataHelpers';

/**
 * merge the data from our main document and the data from all the reference frames, to be considered in selectExternalResourcesToLoadForMainDocument
 */
const selectAllDataToFindExternalResourcesFor = createTypedSelector(
  [selectApiWithPendingChanges, selectAllReferenceFrameData, (state) => state.documentApi.content],
  (
    { content, proposals, relations },
    { content: referenceFrameContent, relations: referenceFrameRelations },
    originalContent
  ) => {
    return {
      originalContent,
      content: { ...content, ...referenceFrameContent },
      relations: [
        ...relations,
        ...referenceFrameRelations.filter((rel) => rel.relationtype === 'IS_PART_OF'),
      ],
      proposals,
    };
  }
);

export const selectExternalResourceAncestorsToLoad = createTypedSelector(
  [
    (state) => state.externalData.resourceAncestorsToLoad,
    (state) => state.externalData.loadedAncestors,
    (state) => state.externalData.failedResourceAncestors,
    selectApiWithPendingChanges,
    selectGenericDocumentRootConfig,
  ],
  (
    resourceAncestorsToLoad,
    loadedAncestors,
    failedResourceAncestors,
    { content, relations },
    rootTypeConfig
  ) => {
    if (
      !rootTypeConfig ||
      (rootTypeConfig.relationsToIgnore && rootTypeConfig.relationsToIgnore.includes('REFERENCES'))
    ) {
      return [];
    }

    const newAncestorsToLoad = new Set<string>();
    const failedAncestorsSet = new Set(failedResourceAncestors);

    relations.forEach((relation) => {
      if (relation.relationtype === 'REFERENCES') {
        /**
         * in the old reducer, we only checked the from href for references.
         * for a teaser linked to a news item, we need the to for example
         */
        [relation.from, relation.to].forEach(({ href }) => {
          if (
            content[href] ||
            failedAncestorsSet.has(href) ||
            resourceAncestorsToLoad.includes(href) ||
            loadedAncestors.includes(href) ||
            !isHref(href)
          ) {
            return;
          }
          if (getResourcePathFromHref(href) === '/content') {
            newAncestorsToLoad.add(href);
          }
        });
      }
    });

    return Object.freeze([...newAncestorsToLoad]);
  }
);

export const selectExternalResourcesToLoad = createTypedSelector(
  [
    selectAllExternalData,
    (state) => state.externalData.resourcesToLoad,
    (state) => state.externalData.failedResources,
    (state) => state.externalData.resourceAncestorsToLoad,
    selectAllDataToFindExternalResourcesFor,
    selectGenericDocumentRootConfig,
    (state) => state.versions,
  ],
  (
    data,
    resourcesToLoad,
    failedResources,
    resourceAncestorsToLoad,
    { content, relations, proposals, originalContent },
    rootTypeConfig,
    versionsData
  ) => {
    if (!rootTypeConfig) {
      return [];
    }
    const excludedRelationTypes = rootTypeConfig.relationsToIgnore || [
      'IS_VERSION_OF',
      'IS_INCLUDED_IN',
    ];
    // we ignore IS_INCLUDED_IN because we should have all the ones we care about from the root request. We (usually) don't care about other places where the document is included in.
    const newResourcesToLoad = new Set<string>();
    const failedResourcesSet = new Set(failedResources);
    relations.forEach((relation) => {
      if (!excludedRelationTypes.includes(relation.relationtype)) {
        [relation.from, relation.to].forEach(({ href }) => {
          if (
            data[href] ||
            content[href] ||
            failedResourcesSet.has(href) ||
            resourcesToLoad.includes(href) ||
            resourceAncestorsToLoad.includes(href) ||
            !isHref(href)
          ) {
            return;
          }
          newResourcesToLoad.add(href);
        });
      }
    });

    const creatorsAndContacts = new Set<
      PersonHref | OrganisationalUnitHref | VosOrganisationalUnitHref
    >();

    // load contacts and creators (can be an href to a person, an ou [sam or vos] or just a string [see quote])
    [...Object.values(content), ...Object.values(originalContent)].forEach((contentItem) => {
      [...(contentItem.creators || []), ...(contentItem.contacts || [])]
        .filter((s) => isEntityHref(s))
        .forEach((href) => creatorsAndContacts.add(href));
    });

    // get creators from proposals
    Object.values(proposals).forEach((proposal) => {
      proposal.creators?.forEach((href) => {
        creatorsAndContacts.add(href);
      });
    });

    // get person from versions
    [...Object.values(versionsData)].forEach((versionsForNode) => {
      versionsForNode.versions.forEach((version) => {
        if (version.person) {
          creatorsAndContacts.add(version.person);
        }
      });
    });

    creatorsAndContacts.forEach((href) => {
      if (
        data[href] ||
        content[href] ||
        failedResourcesSet.has(href) ||
        resourcesToLoad.includes(href)
      ) {
        return;
      }
      newResourcesToLoad.add(href);
    });

    return Object.freeze([...newResourcesToLoad]);
  }
);

export const selectIsExternalDataLoading = createTypedSelector(
  [
    selectAllExternalData,
    (state) => state.externalData.resourcesToLoad,
    (state) => state.externalData.failedResources,
  ],
  (data, resourcesToLoad, failedResources) => {
    return resourcesToLoad.some((href) => !data[href] && !failedResources.includes(href));
  }
);
