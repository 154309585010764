import { ContentNode } from '@newStore/documentUI/documentUITypes';
import { AllExternalData } from '@newStore/externalData/externalDataTypes';
import { AsyncValidationRule, ValidationRule } from '@newStore/validation/validationTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { Merge, RequireExactlyOne } from 'type-fest';
import { DateView } from '@mui/x-date-pickers';
import {
  AttachmentType,
  Content,
  ContentHref,
  ContentRelation,
  ContentRelationType,
  ContentType,
  IsIncludedInRelation,
  IsPartOfRelation,
  LlinkidGoalType,
  WebConfigType,
  WebPage,
} from '../../types/apiTypes';
import { RootState } from '../../types/rootStateTypes';

// We have a circular dependency problem as soon as we want to convert the config files to NodeTypeConfig
// thisFile -> index.ts  for values -> include a config file -> that configFile want to say he is of type NodeTypeConfig

// eslint-disable-next-line no-shadow
export enum NodeType {
  WEBPAGE2 = 'WEBPAGE2',
  PRO_THEME_HOME_FULL = 'PRO_THEME_HOME_FULL',
  PRO_THEME_HOME_TEXT = 'PRO_THEME_HOME_TEXT',
  PRO_THEME_HOME_PICTURE = 'PRO_THEME_HOME_PICTURE',
  PRO_TEXT_PAGE_TILE = 'PRO_TEXT_PAGE_TILE',
  PRO_TEXT_PAGE_STANDALONE = 'PRO_TEXT_PAGE_STANDALONE',
  PRO_DATABASE_TILE = 'PRO_DATABASE_TILE',
  PRO_DATABASE_STANDALONE = 'PRO_DATABASE_STANDALONE',
  PRO_DATABASE_ITEM = 'PRO_DATABASE_ITEM',
  PRO_BLOG_TILE = 'PRO_BLOG_TILE',
  PRO_BLOG_STANDALONE = 'PRO_BLOG_STANDALONE',
  PRO_BLOG_ITEM = 'PRO_BLOG_ITEM',
  PRO_DOWNLOAD_PAGE_TILE = 'PRO_DOWNLOAD_PAGE_TILE',
  PRO_DOWNLOAD_PAGE_STANDALONE = 'PRO_DOWNLOAD_PAGE_STANDALONE',
  PRO_FAQ_PAGE_TILE = 'PRO_FAQ_PAGE_TILE',
  PRO_CURRICULUM_PAGE_TILE = 'PRO_CURRICULUM_PAGE_TILE',
  PRO_TEMPORARY_PAGE_TILE = 'PRO_TEMPORARY_PAGE_TILE',
  PRO_TEMPORARY_PAGE_STANDALONE = 'PRO_TEMPORARY_PAGE_STANDALONE',
  PRO_GLOBAL_DATABASE = 'PRO_GLOBAL_DATABASE',
  PRO_GLOBAL_DATABASE_SECTION = 'PRO_GLOBAL_DATABASE_SECTION',
  PRO_STATIC_PAGE = 'PRO_STATIC_PAGE',
  PRO_THEME_SECTION = 'PRO_THEME_SECTION',
  // 'PRO Menu
  PRO_HOME_PAGE = 'PRO_HOME_PAGE',
  PRO_MENU_LEVEL_2 = 'PRO_MENU_LEVEL_2',
  PRO_MENU_LEVEL_3 = 'PRO_MENU_LEVEL_3',
  PRO_MENU_SECTION = 'PRO_MENU_SECTION',
  // Pro leerplansites so
  SHARED_MINI_DATABASE_ITEM = 'SHARED_MINI_DATABASE_ITEM',
  SHARED_FAQ = 'SHARED_FAQ',
  SHARED_ATTACHMENTS_GROUP = 'SHARED_ATTACHMENTS_GROUP',
  // Pro Newsletter
  TEASER = 'TEASER',
  PRONEWSITEM = 'PRONEWSITEM',
  PRONEWSLETTER = 'PRONEWSLETTER',
  PRONEWSLETTERTEMPLATE = 'PRONEWSLETTERTEMPLATE',
  PRO_NEWSLETTER_SECTION = 'PRO_NEWSLETTER_SECTION',
  // 'WWW
  WWW_STATIC_PAGE = 'WWW_STATIC_PAGE',
  WWW = 'WWW',
  WWWNEWSITEM = 'WWWNEWSITEM',
  JOB_OFFER = 'JOB_OFFER',
  PRESS = 'PRESS',
  // ZILL
  CURRICULUM_ZILL = 'CURRICULUM_ZILL',
  CURRICULUM_ZILL_CLUSTER = 'CURRICULUM_ZILL_CLUSTER',
  CURRICULUM_ZILL_DEVELOPMENT_FIELD = 'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
  CURRICULUM_ZILL_DEVELOPMENT_THEME = 'CURRICULUM_ZILL_DEVELOPMENT_THEME',
  CURRICULUM_ZILL_GENERIC_GOAL = 'CURRICULUM_ZILL_GENERIC_GOAL',
  CURRICULUM_ZILL_CONTENT_GROUP = 'CURRICULUM_ZILL_CONTENT_GROUP',
  CURRICULUM_ZILL_DEVELOPMENT_CONTENT = 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
  CURRICULUM_ZILL_LEERLIJN = 'CURRICULUM_ZILL_LEERLIJN',
  CURRICULUM_ZILL_LEERLIJN_AN = 'CURRICULUM_ZILL_LEERLIJN_AN',
  CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE = 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE',
  CURRICULUM_ZILL_LEERLIJN_CLUSTER = 'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
  CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE = 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE',
  ZILL_ILLUSTRATION = 'ZILL_ILLUSTRATION',
  PRACTICAL_EXAMPLE = 'PRACTICAL_EXAMPLE',
  WEBPAGE = 'WEBPAGE',
  // ODET
  CURRICULUM_ODET = 'CURRICULUM_ODET',
  CURRICULUM_ODET_LEVEL = 'CURRICULUM_ODET_LEVEL',
  CURRICULUM_ODET_LEERGEBIED = 'CURRICULUM_ODET_LEERGEBIED',
  CURRICULUM_ODET_KEY_COMPETENCE = 'CURRICULUM_ODET_KEY_COMPETENCE',
  CURRICULUM_ODET_SUBSECTION = 'CURRICULUM_ODET_SUBSECTION',
  CURRICULUM_ODET_DEVELOPMENT_GOAL = 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
  CURRICULUM_ODET_ENDTERM = 'CURRICULUM_ODET_ENDTERM',
  // 'LLINKID
  LLINKID_CURRICULUM = 'LLINKID_CURRICULUM',
  LLINKID_FOUNDATIONAL_CURRICULUM = 'LLINKID_FOUNDATIONAL_CURRICULUM',
  LLINKID_GOAL_LIST = 'LLINKID_GOAL_LIST',
  LLINKID_GOAL_SECTION = 'LLINKID_GOAL_SECTION',
  LLINKID_GOAL = 'LLINKID_GOAL',
  LLINKID_GOAL_DEMARCATION = 'LLINKID_GOAL_DEMARCATION',
  LLINKID_PEDAGOGICAL_TIP = 'LLINKID_PEDAGOGICAL_TIP',
  LLINKID_EXTRA_GOAL_INFORMATION = 'LLINKID_EXTRA_GOAL_INFORMATION',
  LLINKID_GOAL_EXPLANATION = 'LLINKID_GOAL_EXPLANATION',
  LLINKID_INITIAL_SITUATION = 'LLINKID_INITIAL_SITUATION',
  LLINKID_GUIDING_PRINCIPLE_GROUP = 'LLINKID_GUIDING_PRINCIPLE_GROUP',
  LLINKID_GUIDING_PRINCIPLE = 'LLINKID_GUIDING_PRINCIPLE',
  LLINKID_STATIC_PART = 'LLINKID_STATIC_PART',
  LLINKID_CONTEXT = 'LLINKID_CONTEXT',
  LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT = 'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
  LLINKID_BASIC_MATERIAL_REQUIREMENTS = 'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
  LLINKID_CORRELATION_ENDTERMS = 'LLINKID_CORRELATION_ENDTERMS',
  LLINKID_CONCORDANCE = 'LLINKID_CONCORDANCE',
  // Other generic root document: // Other generic root documents
  VISION_TEXT = 'VISION_TEXT',
  VISION_TEXT_SECTION = 'VISION_TEXT_SECTION',
  TERM = 'TERM',
  MARK_EXPLANATION = 'MARK_EXPLANATION',
  GLOBAL_DOCUMENT = 'GLOBAL_DOCUMENT',
  // "Themes": Refernece frames (ordeningskaders: // "Themes": Refernece frames (ordeningskaders)
  REFERENCE_FRAME = 'REFERENCE_FRAME',
  THEME = 'THEME',
  CURRICULUM_CLUSTER = 'CURRICULUM_CLUSTER',
  CURRICULUM_THEME = 'CURRICULUM_THEME',
  // BUILDING BLOCK
  REFERENCE_GROUP = 'REFERENCE_GROUP',
  REFERENCE = 'REFERENCE',
  SECTION = 'SECTION',
  PARAGRAPH = 'PARAGRAPH',
  IMAGE = 'IMAGE',
  IMAGE_GROUP = 'IMAGE_GROUP',
  VIDEO = 'VIDEO',
  VIDEO_GROUP = 'VIDEO_GROUP',
  EXAMPLE = 'EXAMPLE',
  ATTACHMENT = 'ATTACHMENT',
  ATTACHMENTS_GROUP = 'ATTACHMENTS_GROUP',
  MUSIC = 'MUSIC',
  QUOTE = 'QUOTE',
  FAQ = 'FAQ',
  FAQ_GROUP = 'FAQ_GROUP',
  SUMMARY = 'SUMMARY',
  LEGAL = 'LEGAL',
  WORD_IMPORT = 'WORD_IMPORT', // Not really a node type but only a building bloc
  // nodes only managable in asid: // nodes only managable in aside
  SOURCE = 'SOURCE',
  LINK_GROUP = 'LINK_GROUP',
  PAGE_ATTACHMENTS_GROUP = 'PAGE_ATTACHMENTS_GROUP',
}

// const nodeTypeArr = [NodeTypesEnum.ATTACHMENT, NodeTypesEnum.LLINKID_CURRICULUM];
// const contentTypeArr = [ContentTypes.ATTACHMENT, ContentTypes.LLINKID_CURRICULUM];
// const test = nodeTypeArr.some((type) => type === contentTypeArr[0]);
// console.log(test);

// export const NODETYPES = nodeTypes.reduce((acc, type) => {
//   acc[type] = type;
//   return acc;
// }, {} as any) as { [K in (typeof nodeTypes)[number]]: K & { __brand= 'NodeType' } };

// export type NodeType = (typeof NODETYPES)[keyof typeof NODETYPES];

export type WhiteBlacklistOption = {
  root?: { $$type?: string; foundational?: boolean; type?: ContentType };
  parent?: { $$type?: string; specificForAN?: boolean };
  self?: {
    hasNoChildren?: boolean; // used in the hack of WORD_IMPORT
  };
  webconfiguration?: {
    self?: Array<string>;
    parent?: Array<string>;
    inherited?: Array<string>;
  };
};

export type BuildingBlock = {
  type: NodeType;
  /** max amount of building blocks of this type allowed in the parent */
  max?: number;
  /** position index among siblings of the parent where this building block is allowed */
  position?: number;
  /** in which ancestor this building block is allowed */
  showInAncestorTypes?: NodeType[];
  /** in which ancestor this building block is not allowed */
  hideInAncestorTypes?: NodeType[];
  options?: any;
};

export type FilteredBuildingBlock = Omit<
  BuildingBlock,
  'showInAncestorTypes' | 'hideInAncestorTypes'
>;

export enum RequiredType {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export type EditComponentProperty =
  | keyof Content
  | `${keyof Content}.${string}`
  | 'referenceRelation.to';

export type GenericEditComponent = {
  component: string; // the name of the component = the name of the content property unless specified
  property?: EditComponentProperty; // name of content property if != component name
  label: string; // specify label
  definiteArticle?: boolean; // in dutch will add 'het' instead of default 'de' before the label in sentences (fe error messages)
  valueToString?: (c: Content, a: AllExternalData) => string;
  width?: string | number; // width of the field (bootstrap 12-column)

  /** is the content property required */
  required?: RequiredType;
  /** define a custom message for when the field is empty */
  customRequiredMessage?: string;
};

export type AudienceTabComponent = GenericEditComponent & {
  /** in generic error message the message needed the singular version of the label which is an array and so plural */
  labelSingular: string;
  reactComponent?: string;
  tag?: string; // not sure if we need this
  /** if value is undefined, it means it is taken over from parent (which might have got it from his parent and so on) */
  isInheritedProperty: true;
};

// component that manages Content.attachments of a specific AttachmentType
export type EditAttachmentsComponent = Merge<
  GenericEditComponent,
  {
    property: 'attachments';
    options: {
      type: AttachmentType;
      crop?: boolean | { type: 'rectangle' }; // for images: you can make sure the image is cropped
      fields?: Array<'alt' | 'description' | 'rightsHolder'>; // extra fields/properties which can be added to the attachment
    };
  }
>;

// component that does not edit a Content property, but edits hidden content items that are part of this node
export type EditHiddenChildrenComponent = Merge<
  GenericEditComponent,
  {
    options?: {
      // not used by refactor part, but copied over from what exists
      type?: 'group'; // used in LinkGroup
      modalTitle: 'string'; // used in LinkGroup (in WWW)
      global?: true; // used in page attachmentsGroup, makes sure the PAGE_ATTACHMENTS_GROUP (used to be tag GLOBAL) tag is set (instead of INLINE_ATTACHMENTS_GROUP)
      validExtensions?: Array<string>; // used in downloadDocument (relevant? shouldn't the extensions be limited on every place where attachments are uploaded)
    };
    hiddenChildren: {
      containerType: ContentType; // type of the parent that groups all hidden children together. Can be the type of the node itself or there can be a container node in between (fe LINK_GROUP of SECTION which groups the links). Will be used as a key on which to link errors or proposal diff messages to this component will be linked.
      items: Array<{
        type: ContentType; // type of Content items that are managed by this component
        label: string; // label to show in proposal diffText messages ("${label} added")
      }>;
    };
  }
>;

// component that manages a specific property of Content
export type EditMetaDataComponent = Merge<
  GenericEditComponent,
  {
    options?: any; // extra options which will be passed on to the Angular/React component
  }
>;

export type EditAsideAttachedContent = Merge<
  GenericEditComponent,
  {
    property: '$$html';
    reactComponent: 'AsideAttachedContent';
    options?: {
      showMarkerButton?: boolean;
      hideTermButton?: boolean;
    };
    view?: {
      hideIfEmpty: boolean;
    };
  }
>;

export type EditAsideTitle = Merge<
  GenericEditComponent,
  {
    component: 'title';
    property: 'title';
    reactComponent: 'AsideTitle';
    options?: {
      showMarkerButton?: boolean;
      hideTermButton?: boolean;
    };
  }
>;

export type EditAsideNamedSet = Merge<
  AudienceTabComponent,
  {
    component: 'namedSet';
    property: 'mainstructuresOuTypeCombinations' | 'positions';
    tag: 'mainstructure_outype_ext_comm' | 'doelgroepen';
    reactComponent: 'AsideNamedSet';
  }
>;

export type EditAsideDescription = Merge<
  GenericEditComponent,
  {
    component: 'description';
    property: 'description' | 'shortdescription';
    fullLabel?: string;
    reactComponent: 'AsideDescription';
    maxLength?: number;
    options?: {
      showMarkerButton?: boolean;
      hideTermButton?: boolean;
    };
    view?: {
      hideIfEmpty: boolean;
    };
  }
>;

export type EditAsideContactsOrCreators = Merge<
  GenericEditComponent,
  {
    component: 'contacts' | 'authors';
    property: 'contacts' | 'creators';
    reactComponent: 'AsidePersonPicker';
    options: {
      queryParams: any;
    };
  }
>;

export type EditAsideFreeTextAuthors = Merge<
  GenericEditComponent,
  {
    component: 'authors';
    property: 'creators';
    reactComponent: 'AsideFreeTextAuthor';
  }
>;

export type EditAsideTeaserImportance = Merge<
  GenericEditComponent,
  {
    component: 'teaserImportance';
    property: 'importance';
    reactComponent: 'AsideTeaserImportance';
  }
>;

export type EditAsideCheckbox = Merge<
  GenericEditComponent,
  {
    component: string;
    property: EditComponentProperty;
    reactComponent: 'AsideCheckbox';
  }
>;

export type EditAsideStaticHtml = Merge<
  GenericEditComponent,
  {
    component: 'staticHtml';
    property: '$$html';
    reactComponent: 'AsideStaticHtml';
  }
>;

export type EditAsideIdentifier = Merge<
  GenericEditComponent,
  {
    component: 'identifier';
    property: 'identifiers';
    reactComponent: 'AsideIdentifier';
    view?: {
      hideIfEmpty: boolean;
    };
  }
>;

export type EditAsidePreviousVersion = Merge<
  GenericEditComponent,
  {
    component: 'previousVersion';
    reactComponent: 'AsidePreviousVersion';
    readonly: true;
  }
>;

export type EditAsideValidityPeriod = Merge<
  GenericEditComponent,
  {
    component: 'validityPeriod';
    reactComponent: 'AsideValidityPeriod';
    readonly: true;
  }
>;

export type EditAsideNewsletterSender = Merge<
  GenericEditComponent,
  {
    component: 'newsletterSender';
    reactComponent: 'AsideNewsletterSender';
  }
>;

export type EditAsideSources = Merge<
  GenericEditComponent,
  {
    component: 'sources';
    property?: never;
    reactComponent: 'AsideSources';
    hiddenChildren: {
      containerType: ContentType.STRUCTURED_DOCUMENT;
      items: [
        {
          type: ContentType.SOURCE;
          label: string;
        }
      ];
    };
  }
>;

export type EditAsideVersion = Merge<
  GenericEditComponent,
  {
    component: 'version';
    reactComponent: 'AsideVersion';
    readonly: true;
  }
>;

export type EditAsidePreviousVersionItems = Merge<
  GenericEditComponent,
  {
    component: 'previousVersionItems';
    reactComponent: 'AsidePreviousVersionItems';
    property?: never;
    options: {
      relationTypes: ['REPLACES']; // ContentRelation['relationtype'];
      modal: 'llinkidPreviousVersionModal' | 'llinkidGoalRelationsModal';
      modalTitle: string;
      listColumn?: 'description';
      placeholder: string;
      relation: string;
      errorMessage: string;
      type:
        | ContentType.LLINKID_GOAL
        | ContentType.LLINKID_PEDAGOGICAL_TIP
        | ContentType.LLINKID_EXTRA_GOAL_INFORMATION
        | ContentType.LLINKID_GOAL_EXPLANATION
        | ContentType.LLINKID_INITIAL_SITUATION;
    };
  }
>;

export type EditAsideLlinkidGoalType = Merge<
  GenericEditComponent,
  {
    component: 'llinkidGoalType';
    property: 'llinkidGoalType';
    reactComponent: 'AsideSimpleDropDown';
    options: {
      clearable: boolean;
      options: Array<{ key: LlinkidGoalType; label: string }>;
    };
  }
>;

export type EditAsideImportance = Merge<
  GenericEditComponent,
  {
    component: 'importance';
    property: 'importance';
    reactComponent: 'AsideSimpleDropDown';
    options: {
      clearable: boolean;
      options: Array<{ key: string; label: string }>;
    };
  }
>;

export type EditAsideRealisationPeriod = Merge<
  GenericEditComponent,
  {
    component: 'realisationPeriod';
    property: 'realisationPeriod';
    reactComponent: 'AsideSimpleDropDown';
    options: {
      clearable: boolean;
      options: Array<{ key: number; label: string }>;
    };
  }
>;

export type EditAsideSimpleDropDown =
  | EditAsideLlinkidGoalType
  | EditAsideImportance
  | EditAsideRealisationPeriod;

export type EditAsideSecondaryEducationType = Merge<
  GenericEditComponent,
  {
    component: 'secondaryEducationTypes';
    property: 'secondaryEducationTypes';
    reactComponent: 'AsideSecondaryEducationType';
    options: {
      clearable: boolean;
    };
  }
>;

export type EditAsideGoalRelations = Merge<
  GenericEditComponent,
  {
    component: 'goalImplements' | 'goalOdetEndTermReferences' | 'goalRelations';
    reactComponent: 'AsideGoalRelations';
    property?: never;
    options: {
      relationTypes: Array<ContentRelationType>;
      modal: 'llinkidGoalRelationsModal' | 'llinkidGoalOdetEndTermReferencesModal';
      listColumn: 'description' | 'title';
      modalTitle?: string;
      revertedRelationDirection?: boolean;
      toType: ContentType.LLINKID_GOAL | ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL;
      readOnly?: boolean;
    };
  }
>;

type DatePickerOptions = {
  openTo: DateView;
  shouldDisableDate?: (day: Date) => boolean;
};

export type EditAsideExpiryDatePicker = Merge<
  GenericEditComponent,
  {
    component: 'expiryDate';
    property: 'expiryDate';
    reactComponent: 'AsideDatePicker';
    options: DatePickerOptions;
  }
>;

export type EditAsideIssuedDatePicker = Merge<
  GenericEditComponent,
  {
    component: 'issued';
    property: 'issued';
    reactComponent: 'AsideIssuedDatePicker';
    options: DatePickerOptions;
  }
>;

export type EditAsideDateToSendDatePicker = Merge<
  GenericEditComponent,
  {
    component: 'dateToSend';
    property?: never;
    reactComponent: 'AsideDateToSendPicker';
    options: DatePickerOptions;
  }
>;

export type EditAsideReferenceFrameReferences = Merge<
  GenericEditComponent,
  {
    component: 'educationalComponents' | 'educationalPointers';
    reactComponent: 'AsideReferenceFrameReferences';
    property?: never;
    options: {
      relationTypes: Array<ContentRelationType>;
      modal: 'llinkidReferenceModal';
      listColumn: 'title';
      revertedRelationDirection: boolean;
      referenceFrame: ContentHref;
    };
  }
>;

export type EditAsideColor = Merge<
  GenericEditComponent,
  {
    component: 'color';
    property: 'color';
    reactComponent: 'AsideColor';
  }
>;

export type EditAsideLinks = Merge<
  GenericEditComponent,
  {
    component: 'linkGroup';
    reactComponent: 'AsideLinks';
    options?: {
      type: 'group';
      modalTitle?: string;
    };
    hiddenChildren?: {
      containerType: ContentType.LINK_GROUP;
      items: Array<{
        type: ContentType.REFERENCE;
        label: string;
      }>;
    };
  }
>;

export type EditAsideCurriculumTheme = Merge<
  GenericEditComponent,
  {
    component: 'curriculumTheme';
    reactComponent: 'AsideCurriculumTheme';
    property: 'themes';
    subLabel: string;
  }
>;

export type EditAsideCurriculumSelector = Merge<
  GenericEditComponent,
  {
    component: 'curriculumSelector';
    reactComponent: 'AsideCurriculumSelector';
    property: 'themes';
    showInAncestorTypes?: NodeType[];
  }
>;

export type EditAsideTeaserLinkedContent = Merge<
  GenericEditComponent,
  {
    component: 'teaserLinkedContent';
    reactComponent: 'AsideTeaserLinkedContent';
    hiddenChildren: {
      containerType: ContentType.TEASER;
      items: [
        {
          type: ContentType.REFERENCE;
          label: 'link';
        }
      ];
    };
  }
>;

export type EditAsideConcordanties = Merge<
  GenericEditComponent,
  {
    component: 'concordanties';
    reactComponent: 'AsideConcordanties';
  }
>;

export type EditAsideDevelopmentPhase = Merge<
  GenericEditComponent,
  {
    component: 'developmentPhase';
    reactComponent: 'AsideDevelopmentPhase';
  }
>;

export type EditComponent =
  | EditAsideSources
  | EditAsideSimpleDropDown
  | EditAsideExpiryDatePicker
  | EditAsideDateToSendDatePicker
  | EditAsideIssuedDatePicker
  | EditAsideIdentifier
  | EditAsidePreviousVersion
  | EditAsideStaticHtml
  | EditAsideTitle
  | EditAsideSecondaryEducationType
  | EditAsideNewsletterSender
  | EditAsideDescription
  | EditAsideNamedSet
  | EditAsideValidityPeriod
  | EditAsideVersion
  | EditAsidePreviousVersionItems
  | EditAsideGoalRelations
  | EditAsideTeaserImportance
  | EditAsideReferenceFrameReferences
  | EditAsideCheckbox
  | EditAsideFreeTextAuthors
  | EditAsideAttachedContent
  | EditAsideContactsOrCreators
  | EditAsideColor
  | EditAsideLinks
  | EditAsideCurriculumTheme
  | EditAsideCurriculumSelector
  | EditAsideTeaserLinkedContent
  | EditAsideConcordanties
  | EditAsideDevelopmentPhase
  | ((EditMetaDataComponent | EditAttachmentsComponent | EditHiddenChildrenComponent) & {
      view?: any; // is this used in the refactor? what does it do?
      type?: string; // used in PRONEWLSETTER...
      validations?: any; // obsolete will be deleted in next branch
      avoidDispatchDownloadTag?: boolean;
      readonly?: boolean;
      /** max amount of characters for the content property */
      maxLength?: number;
      reactComponent?: never;
    });

export type FilterableEditComponent = EditComponent & {
  /** component will only appear if the node has any of the given NodeTypes as an ancestor */
  showInAncestorTypes?: NodeType[];
  /** component will not appear if the node has any of the given NodeTypes as an ancestor */
  hideInAncestorTypes?: NodeType[];
};

export type RootEditComponent = EditComponent & {
  // a rootnode cannot have these properties.
  showInAncestorTypes?: never;
  hideInAncestorTypes?: never;
};

export const isEditAttachmentsComponent = (e: EditComponent): e is EditAttachmentsComponent =>
  'property' in e && e.property === 'attachments';

export const isHiddenChildrenComponent = (e: EditComponent): e is EditHiddenChildrenComponent =>
  'hiddenChildren' in e;

export type FilteredEditComponent = RootEditComponent;
//  Omit<
//   FilterableEditComponent,
//   'showInAncestorTypes' | 'hideInAncestorTypes'
// >;

export type InlineField = 'title' | 'description' | 'html';

export type RootNodeCategory = 'PRO' | 'ZILL' | 'WWW' | 'LEERPLANSITES_SO' | 'LEERPLAN' | 'GENERAL';

type GenericNode = {
  information: {
    /** used in several places like error messages to add 'de' (false) or 'het' (true) before the label */
    definiteArticle?: boolean;
    /** the label used in the document, error messages and proposal diffText */
    single: string;
    /** the plural version of the label for some cases. */
    plural: string;
    /** not used in code, but useful to add some information about the NodeType */
    description?: string;
    /** used in the ribbon */
    icon?: any;
    ribonIcon?: any;
    ribbonTitle?: string;
    /** used in List screen to group items (only relevant in RootNodeConfig) */
    category?: RootNodeCategory; // TODO: make it only for root node types.
    conditionalTitle?: (any) => string; // used for download/bijlagegroep in the aside as title, when working on the aside we will split those two up in different configs and this property can go
  };
  /** properties that also exist on Content. Used for
   * 1. Matching with content item do determine the node type
   * 2. when creating a node type adding these default properties to the created Content item.
   */
  // node: {
  //   // 1st parameter for NodeType match (in future (if all NodeTypeConfigs are TypeScript and less branches) => nodeTypeMatch: { content, webTemplateHref, rootType})
  //   type?: ContentType;
  //   tags?: ContentTag[];
  //   specificForAN?: boolean;
  //   importance?: 'LOW' | 'MEDIUM' | 'HIGH';
  //   foundational?: boolean;
  // } | null; // null: you never want to match, it has some other purpose (temporary SECTION_NEW, but alos WORD_IMPORT if that needs to stay)
  /**
   * any content child of this node will not be visible in the document (and should be managed from the aside as part of this node)
   * this also means that error messages and proposal messages for the content children are also related to this node
   */
  hideChildrenInDocument?: true;
  /** a node can be deprecated, but still there to show in old documents (for example Odet leergebied in Odet 2014). So it is valid in the document, but will no longer be selectable in the ribbon. */
  isDeprecated?: true;
};

/**
 * Configuration for Content items that can only occur as hidden items of another node and managed from the aside, so they are not a Node
 * therefore these kind of configs will not have a lot of properties since the only thing you need to detect is that they are not a node and they are hidden
 */
export type HiddenContentItemConfig = GenericNode & {
  hideInDocument: true;
};

/**
 * As opposed to HiddenContenItemConfig, Configuration for Content Items that become visible as a node in the Document
 * So this is the configuration for any kind of node in the document, a root or a "building block" below it.
 */
type DocumentNodeConfig = GenericNode & {
  /** content properties that are added when creating the node, but on which we don't want to match in the node definition */
  createDefaults?: any; // Partial<Content> <- see PARAGRAPH, I can not make Typescript happy
  /**
   * used for LLINKID, to create some children by default
   */
  createChildDefaults?: Array<{
    type: ContentType;
    readorder: number;
  }>;
  buildingBlocks?: Array<BuildingBlock>;
  edit: Array<FilterableEditComponent>;
  /**
   * on the audience tab you also get an accessRights component.
   * This is not automatically the same as the root like the "real audiencecomponents", but it only needs to be there for a limited set of nodeTypes (fe. attachments_group).
   * In most cases just a boolean to have the default accessRightsComponent, but can also be an Object overwriting some properties of the default accessRightsComponent
   */
  addAccessRightsToAudienceTab?: boolean | AudienceTabComponent;
  /** you are not allowed to delete this node by selecting it in Document View */
  isNotDeletable?: true;

  // not sure for the following properties for which kind of nodes (root or building block or both) this is
  createModal?: any;
  createInModal?: boolean; // are both createModal and createInModal used???
  isImportable?: boolean;
  documentViewIdentifier?: string; // what is this?
  globalAttachmentsGroupContainer?: boolean; // used in documentHelpers.js method which is refered by asideAttachmentsComponent but you can just look at the edit.some(c => c.component === 'attachments' && c.options?.global === true)
};

/**
 * Configuration for nodes that are the root of a document
 * Need also a lot of extra properties that are necessary for:
 *  - the list screen
 *  - properties that are applicable for the whole document
 *  - properties that are necessary for the initialisation of the document
 */
export type RootNodeConfig = DocumentNodeConfig & {
  /** Is this node searchable as a document in the List screen (if user has access to this nodetype) */
  isSearchable: boolean;
  /** Will be in the list of documents to create in the list screen (if user has access to this nodetype) */
  isCreatable?: boolean;
  /** if true edits on published documents will happen with proposals */
  allowSuggestions: boolean;
  /** node types that will be visualised in the table of contents */
  tocTypes: Array<NodeType>;

  edit: Array<RootEditComponent>;

  /**
   * when fetching the document these actions spicific for this NodeType will be dispatched.
   * This property is only on the non extended root type, because this happens before the /web/pages are loaded so they are solely based on the content item (generic NodeType)
   */
  onLoadActions?: Array<PayloadAction<any>>; // introduced in the refactored code
  preloadActions?: Array<any>; // old Redactie but still called in documentApiSaga => TODO! merge
  /** by default IS_INCLUDED_IN and IS_VERSION_OF. Prevent fetching of items linked to these relations (because like in Zill you do this in his own saga because the data model is more complex there) */
  relationsToIgnore?: ContentRelation['relationtype'][];
  /**
   * this root node AND all other child nodes in the document will also have the audience tab in the aside with the same components specified by the root
   * Audience properties (coverage, mainstructureAndOuTypes, positions) are inherited by nodes if they don't specify them themselves
   * Only exception are included Teasers which can define their own audience tab (because they are also a RootNodeType) that could in theory have different (more) components as the Newsletter (at this moment they are the same components)
   */
  audienceTab?: Array<AudienceTabComponent>;
  /**
   * if this node type will always have the same Web Template, on creation it will always get automatically a Web Page with these properties
   * When refactoring aside we can move this more generically to an onCreateAction
   */
  webconfiguration?: Partial<WebPage & { type?: string }>;
  websiteEdition?: Array<{ domain: string }>;
  ribbon?: {
    customCondition?: {
      type: string;
      childrenCount: number;
    }[];
    importFromWord?: boolean;
  };
  /** If set to true, you will not be able to delete any node in the document if the document is published */
  disableDeleteWhenIssued?: boolean;
  customEditorOptions?: {
    // in PRONEWSLETTER already defined on root as it should, but often defined in edit where it will be removed
    showMarkerButton?: boolean;
    hideTermButton?: boolean;
  };
  isCloneable?: boolean;
  previewModes?: Array<{ type: string; name: string; location: string }>;
  /** in this document you can only add these types in a Reference Group */
  referenceGroupTypes?: any; // TODO replace any to the real options from keys of constants.referenceGroupTypes
};

/**
 * Configuration for Root nodes that are a more specific instance of some other generic root node
 * for example all the Pro Theme configurations that are a specific instance of WEBPAGE2
 * Given the webConfiguration they have they become a more specific instance of WEBPAGE2 with other configuration properties
 */
export type ExtendedRootNodeConfig = DocumentNodeConfig & {
  /** means this is a more specific instance of the given generic NodeType */
  extends: NodeType;
  /** 2nd parameter for NodeType match */
  // webTemplateHref?: WebTemplateHref;
  tocTypes: Array<NodeType>;
  audienceTab?: Array<AudienceTabComponent>;
};

// Configuration for Building Block nodes (non-root-nodes)
// they have all kind of properties related to editing the nodes in the document (creating a new one, replacing, deleting, collapsing)
export type BuildingBlockNodeConfig = DocumentNodeConfig & {
  /** if true, you will be able to collapse the node */
  isCollapsible: boolean;
  /** when opening this document this node will be collapsed (if the document has more nodes than settings.defaultCollapseNodeCountTreshold [usually set to 100]) */
  isCollapsedByDefault?: boolean;
  /** after selecting and deleting a node: show a pop-up where user needs to confirm if he is sure he wants to delete the node (delete in list screen always asks confirmation) */
  confirmDelete?: true;
  /** conditionaly show a pop-up where user needs to confirm if he is sure he wants to delete the node with custom message */
  customConfirmDeleteMessage?: (node: any) => string | undefined;
  /** the node will not be drag-and-droppable */
  isNotDraggable?: true;
  /** you will not be able to open the aside and will not be able to edit inline */
  readOnlyNode?: true;
  /** show the NodeTypeConfig.information.single in the document (because there will be no text for this node, it is a container) */
  showPlaceholder?: true;
  /**
   * when a new building block node is dropped from the ribbon, there are three options:
   * - the carrot needs to be focused directly in the InlineEditor of this new node in the Document (focusOnfield: {the field in which the carrot needs to focus})
   * - the aside needs to be opened for this new node (openAside: true)
   * - nothing needs to be done (openAside: false)
   */
  onNewNodeDropped: RequireExactlyOne<{
    focusOnField: InlineField; // focus the editor to this field when a new node is dropped
    openAside: boolean; // open the aside when a new node is dropped
  }>;
  themesDisplaySuffix?: string;
  nodeConditional?: Array<{
    field: string;
    value: any;
    condition: {
      parent?: Array<WebConfigType>;
    };
  }>;
};

/**
 * Configuration for building blocks that are some more specific instance of some other generic building block
 * Given the webconfiguration and/or the type of document in which they are in they become a more specific instance of the building block
 * For example especially SECTION can be very differenct in each kind of document with other meta properties and so they need their own configuration file
 * This is used for instance with the Building Blocks component where only generic Building Blocks are listed
 * so for dragging and dropping it is important: the parent lists the allowed building blocks but that also means you can drop here a more specific instance
 */
export type ExtendedBuildingBlockNodeConfig = BuildingBlockNodeConfig & {
  /** means this is a more spicific instance of the given generic NodeType */
  extends: NodeType; // extend another NodeTypeConfig
  // /** 2nd parameter for NodeType match */
  // webTemplateHref?: WebTemplateHref;
  // /** 3rd parameter for NodeType match: if the root node is of this NodeType */
  // documentTypes?: Array<NodeType>;
};

export type NodeTypeConfig =
  | RootNodeConfig
  | ExtendedRootNodeConfig
  | BuildingBlockNodeConfig
  | ExtendedBuildingBlockNodeConfig
  | HiddenContentItemConfig
  | (RootNodeConfig & BuildingBlockNodeConfig); // Teaser

export type NodeTypeConfigApplied = Merge<
  NodeTypeConfig,
  {
    edit: Array<FilteredEditComponent>;
    buildingBlocks?: Array<FilteredBuildingBlock>;
    audienceTab?: Array<AudienceTabComponent>;
  }
>;

export const isGenericRootNodeConfig = (
  nodeTypeConfig: NodeTypeConfig
): nodeTypeConfig is RootNodeConfig =>
  'isSearchable' in nodeTypeConfig && !('extends' in nodeTypeConfig);

export const isRootNodeConfig = (
  nodeTypeConfig: NodeTypeConfig
): nodeTypeConfig is
  | RootNodeConfig
  | ExtendedRootNodeConfig
  | (RootNodeConfig & BuildingBlockNodeConfig) => 'tocTypes' in nodeTypeConfig;

export const isBuildingBlockNodeConfig = (
  nodeTypeConfig: NodeTypeConfig
): nodeTypeConfig is
  | BuildingBlockNodeConfig
  | ExtendedBuildingBlockNodeConfig
  | (RootNodeConfig & BuildingBlockNodeConfig) =>
  'isCollapsible' in nodeTypeConfig && 'onNewNodeDropped' in nodeTypeConfig;

export const isHiddenNodeConfig = (
  nodeTypeConfig: NodeTypeConfig
): nodeTypeConfig is HiddenContentItemConfig => 'hideInDocument' in nodeTypeConfig;

export type NodeTypeSelectors = Record<
  NodeType,
  {
    nodeSelector?: (state: RootState, href: ContentHref) => ContentNode;
    deleteFromListValidations?: Array<(key: string) => Promise<any>>;
    deleteValidations?: Array<(node: any) => any>;
    createValidations?: Array<{ cmd: any; failAction: any }>;
    validationRules?: Array<{
      rule: ValidationRule | AsyncValidationRule; // without apply stuf this can be an array of rules instead of object
    }>;
  }
>;

export type DropExistingNode = {
  items: Array<{
    node: { type: NodeType; href: ContentHref };
    relation: IsPartOfRelation | IsIncludedInRelation;
    /**
     * The type to compare the buildingblocks with to check if they are allowed to be dropped
     */
    buildingBlockType: NodeType;
  }>;
};

export type DropBuildingBlock = {
  items: Array<{
    node: { type: NodeType };
    newNode: true;
    /**
     * The type to compare the buildingblocks with to check if they are allowed to be dropped
     */
    buildingBlockType: NodeType;
  }>;
};

export type DropType = DropExistingNode | DropBuildingBlock;

export enum HoverPositionEnum {
  ABOVE = 'ABOVE',
  IN = 'IN',
  BELOW = 'BELOW',
}

export type HoverPosition = HoverPositionEnum | null;
