import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ContentHref, ContentKey, Version } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { loadVersions, setVersions } from './versionsState';
import { getVersionsForContent } from './versionsDataAccess';

// Saga to handle loading versions
function* handleLoadVersions(action: PayloadAction<ContentHref>) {
  try {
    const loadVersion = yield select(
      (state: RootState) => state.versions[action.payload]?.isLoading === true
    );
    if (!loadVersion) {
      return;
    }
    const versions: Version[] = yield call(getVersionsForContent, action.payload);
    yield put(setVersions({ href: action.payload, versions }));
  } catch (error) {
    console.error('Failed to load versions', error);
    // Handle error appropriately, e.g., dispatch an error action
  }
}

// Saga to handle updating the aside view model
function* handleUpdateAsideViewModel(action: PayloadAction<{ editKey: ContentKey }>) {
  yield put(loadVersions(`/content/${action.payload.editKey}`));
}

// Watcher saga to watch for loadVersions action
export function* watchLoadVersions() {
  yield takeLatest(loadVersions.type, handleLoadVersions);
  yield takeLatest('UPDATE_ASIDE_VIEW_MODEL', handleUpdateAsideViewModel);
}
