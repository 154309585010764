import { ContentHref, Version } from '@generalTypes/apiTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type VersionsState = Record<ContentHref, { versions: Version[]; isLoading: boolean }>;

const initialState: VersionsState = {};

const versionsState = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    loadVersions: (state, action: PayloadAction<ContentHref>) => {
      if (!state[action.payload]) {
        state[action.payload] = { versions: [], isLoading: true };
      }
    },
    setVersions: (state, action: PayloadAction<{ href: ContentHref; versions: Version[] }>) => {
      state[action.payload.href] = { versions: action.payload.versions, isLoading: false };
    },
  },
});

export const { loadVersions, setVersions } = versionsState.actions;

export default versionsState;
