import { extractContentAndRelations } from '@newStore/documentApi/documentApiHelpers';
import { arrayToObjectMap } from '@newStore/genericHelpers';
import { referenceFramesByHref } from '@newStore/referenceFrames/referenceFrames';
import { addNotificationAction } from '@store/actions/notificationActions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ContentHref, ContentKey } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import { getCachedDocumentByRoot } from '../documentApi/documentApiDataAccess';
import { selectIsReferenceFrameLoaded } from './referenceFramesSelectors';
import { loadReferenceFrame, loadReferenceFrameSuccess } from './referenceFramesState';

// TODO: fetch the reference frame again, if you are in a document of type reference frame, and we have that loaded.
function* fetchReferenceFrame({
  payload: { referenceFrame },
}: PayloadAction<{ referenceFrame: ContentHref }>) {
  try {
    const isReferenceFrameLoaded: boolean = yield select((state: RootState) =>
      selectIsReferenceFrameLoaded(state, referenceFrame)
    );
    if (isReferenceFrameLoaded) {
      return;
    }
    const contentApiResults = yield call(
      getCachedDocumentByRoot,
      referenceFrame.split('/').pop() as ContentKey
    );
    const { content: contentArray, relations: apiRelations } =
      extractContentAndRelations(contentApiResults);
    const content = arrayToObjectMap(contentArray);
    const relations = apiRelations.filter((rel) => rel.relationtype === 'IS_PART_OF');
    yield put(loadReferenceFrameSuccess({ referenceFrame, content, relations }));
  } catch (error) {
    console.error(
      `error fetching ${referenceFramesByHref[referenceFrame]} - ${referenceFrame} reference frame`
    );
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: `Er is een onverwachte fout opgetreden bij het ophalen van het ${referenceFramesByHref[referenceFrame]} ordeningskader.'`,
      })
    );
  }
}

export function* referenceFramesSaga() {
  yield takeEvery(loadReferenceFrame.match, fetchReferenceFrame);
}
