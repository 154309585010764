import React, { useEffect } from 'react';
import { EditAsideConcordanties } from '@nodeTypeConfig/configTypes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import {
  addRelationAction,
  editZillCurriculumAsFrame,
  patchRelationAction,
  removeRelationAction,
  setSelectedZillCurriculumAsFrame,
} from '@store/actions/documentActions';
import { selectIsRoot } from '@newStore/documentUI/documentUISelectors';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { useEditHref } from '../../../hooks/UseEditHref';
import {
  selectAsideConcordanties,
  selectRootOdet,
  selectRootOdetHref,
} from './asideConcordantiesSelectors';

const AsideConcordanties: React.FC<{
  config: EditAsideConcordanties;
}> = () => {
  const editHref = useEditHref();
  const dispatch = useDispatch();
  const confirmationService = getAngularService('ConfirmationService');
  const modalWindowService = getAngularService('ModalWindowService');

  const isRoot = useSelector((state: RootState) => selectIsRoot(state, editHref));
  const rootOdetHref = useSelector((state: RootState) => selectRootOdetHref(state));
  const rootOdet = useSelector((state: RootState) => selectRootOdet(state));

  // the odet is not loaded if user changes it in root element
  useEffect(() => {
    if (rootOdetHref) {
      dispatch(loadReferenceFrame({ referenceFrame: rootOdetHref }));
    }
  }, [dispatch, rootOdetHref]);

  const concordanties = useSelector((state: RootState) =>
    selectAsideConcordanties(state, editHref)
  );

  const concordantieNotAlreadyAdded = (concordantieToAdd) => {
    return !concordanties.some((concordantie) => {
      return concordantieToAdd.key === concordantie.contentKey;
    });
  };

  const isLoading = isRoot
    ? !rootOdet?.isLoaded && concordanties.length > 0
    : rootOdet && !rootOdet.isLoaded;

  const currentNode = useSelector((state: RootState) => selectCurrentEditingNode(state));

  const concordantiesEditionDisabled =
    currentNode.type === 'CURRICULUM_ZILL' && concordanties.length > 0;

  const deleteConcordantie = async (relation) => {
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(removeRelationAction(relation.key));
    }
  };

  const openModal = async (relation = undefined) => {
    const opts = {
      component: 'concordantieModal',
      dataForModal: {
        fromType: currentNode.type,
        isRoot,
        relation,
      },
    };
    return modalWindowService.open(opts);
  };

  const editConcordantie = async (relation) => {
    const response = await openModal(relation);
    const action = isRoot ? editZillCurriculumAsFrame : patchRelationAction;
    const selectedRelation = isRoot ? response.odet : response.endterm;

    if (concordantieNotAlreadyAdded(selectedRelation)) {
      const patchToApply = {
        to: {
          href: selectedRelation.$$meta.permalink,
          $$expanded: selectedRelation,
        },
      };

      dispatch(action(relation.key, patchToApply));
    }
  };

  const addConcordanties = async () => {
    const response = await openModal();
    const concordantiesToAdd = isRoot ? [response.odet] : response.endterms;
    const action = isRoot ? setSelectedZillCurriculumAsFrame : addRelationAction;

    concordantiesToAdd.filter(concordantieNotAlreadyAdded).forEach((target) => {
      const newRelation = {
        relationtype: 'IS_VERSION_OF',
        strength: 'MEDIUM',
        from: {
          href: editHref,
        },
        to: {
          href: target.$$meta.permalink,
          $$expanded: target,
        },
      };

      dispatch(action(newRelation));
    });
  };

  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <div className="panel-controls panel-controls-left">
          <h3 className="panel-title">Concordanties</h3>
        </div>
        <div className="panel-controls panel-controls-right">
          <div className="btn-toolbar">
            {!concordantiesEditionDisabled && (
              <a
                className="btn btn-default"
                onClick={() => {
                  addConcordanties();
                }}
              >
                <span className="glyphicon glyphicon-add"></span>
                <span>Voeg toe</span>
              </a>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="shimmer narrowLine"></div>
      ) : (
        <div className="structure">
          {concordanties.map((concordantie) => (
            <div className="row table-row" key={concordantie.key}>
              <div className="col-md-2" style={{ fontWeight: 'bold' }}>
                {concordantie.goalIdentifier}
              </div>
              <div className="col-md-10">
                {concordantie.title || 'Relatie niet gevonden in ODET document'}
              </div>
              <div className="btn-group btn-group-xs row-actions" role="group" aria-label="...">
                {concordantie.title && (
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      editConcordantie(concordantie);
                    }}
                  >
                    <span className="glyphicon glyphicon-edit"></span>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => {
                    deleteConcordantie(concordantie);
                  }}
                >
                  <span className="glyphicon glyphicon-delete"></span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

AsideConcordanties.displayName = 'AsideConcordanties';

export default AsideConcordanties;

AsideConcordanties.propTypes = {};
