import React, { useState } from 'react';
import { Popper, Typography } from '@mui/material';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { selectIsVersionLoading, selectVersions } from './asideNodeVersionsSelectors';
import './asideNodeVersions.scss';

const AsideNodeVersions: React.FC<void> = () => {
  const editHref = useEditHref();

  const versions = useTypedSelector((state) => selectVersions(state, editHref));

  const isLoading = useTypedSelector((state) => selectIsVersionLoading(state, editHref));

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    if ((versions?.length ?? 0) > 0) {
      setOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  if (!versions || isLoading) {
    return null;
  }

  return (
    <div>
      <Typography
        className="aside-node-versions"
        aria-owns={open ? 'versions-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        Laatst gewijzigd: {versions.length === 0 && !isLoading && 'Geen wijzigingen gevonden'}
        {versions.length > 0 && versions[0]?.date}
      </Typography>
      <Popper
        id="versions-popover"
        sx={{
          backgroundColor: 'white',
          zIndex: '9999',
          padding: '0 1rem',
          lineHeight: '1.6',
          border: '1px solid #a5a5a5',
          borderRadius: '2px',
          maxHeight: '400px',
          overflow: 'scroll',
          boxShadow: '2px 2px 4px 0px #d4d4d4',
        }}
        open={open}
        anchorEl={anchorEl}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={handleMouseLeave}
      >
        <div className="versions-list">
          <label className="control-label control-label-inline">Laatst gewijzigd</label>
          <ul className="list-unstyled">
            {versions.length > 0 &&
              versions.map((version, index) => (
                <li key={index}>
                  {version.date} - {version.personName}
                </li>
              ))}
          </ul>
        </div>
      </Popper>
    </div>
  );
};

AsideNodeVersions.displayName = 'AsideNodeVersions';

export default AsideNodeVersions;
