import {
  getBorderLeftColor,
  getUserColor,
} from '@UI/mainScreen/changeIndicators/ProposalIndicatorHelpers';
import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { Popover } from '@mui/material';
import { selectProposalMetaInfoAside } from '@newStore/documentUI/transformProposal/proposalSelectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RequireExactlyOne } from 'type-fest';
import './AsideChangeIndicator.scss';

export type AsideChangeIndicatorProps = RequireExactlyOne<
  {
    htmlChangeMessage: string | null;
    children: React.ReactNode;
  },
  'children' | 'htmlChangeMessage'
>;

/**
 * Shows the aside change indicator for proposals
 * if you pass a htmlChangeMessage, it will only render the indicator when htmlChangeMessage is not null
 * if you pass children, it will always render the change indicator.
 * you can not pass both children and htmlChangeMessage
 * @param param0
 * @returns
 */
const AsideChangeIndicator: React.FC<AsideChangeIndicatorProps> = ({
  htmlChangeMessage,
  children,
}) => {
  const proposalMetaInfo = useSelector((state: RootState) =>
    selectProposalMetaInfoAside(state, state.documentUI.currentEditingNode as ContentHref)
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mode = useSelector((state: RootState) => state.documentUI.mode);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (htmlChangeMessage === null) {
    return null;
  }

  if (!htmlChangeMessage && !children) {
    return null;
  }

  if (!proposalMetaInfo) {
    return null;
  }

  return (
    <div className="redactie-proposal-indicator">
      <div
        className="proposal-line-container"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div
          className="line"
          style={{
            borderLeftStyle:
              proposalMetaInfo.status === 'SUBMITTED_FOR_REVIEW' ? 'solid' : 'dashed',
            borderLeftColor: getBorderLeftColor(proposalMetaInfo, mode),
            borderRight: proposalMetaInfo.authors.length > 1 ? `2px solid #3499eb` : undefined,
            paddingRight: proposalMetaInfo.authors.length > 1 ? '2px' : '4px',
          }}
        ></div>
      </div>
      {anchorEl && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <div className="proposal-diff">
            <div className="proposal-diff-header">
              <span style={{ color: getUserColor(proposalMetaInfo) }}>
                {`Wijzigingen door:`}
                <strong>{` ${proposalMetaInfo.authorsDescription}${
                  proposalMetaInfo.submittedOn ? ` - ${proposalMetaInfo.submittedOn}` : ''
                }`}</strong>
              </span>
            </div>

            {htmlChangeMessage && (
              <div
                className="proposal-diff-html"
                dangerouslySetInnerHTML={{
                  __html: htmlChangeMessage,
                }}
              ></div>
            )}
            {!htmlChangeMessage && <div className="proposal-diff-html">{children}</div>}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default AsideChangeIndicator;
