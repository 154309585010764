import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { EditAsideDevelopmentPhase } from '@nodeTypeConfig/configTypes';
import {
  addRelationAction,
  patchRelationAction,
  removeRelationAction,
} from '@store/actions/documentActions';
import { useDispatch, useSelector } from 'react-redux';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectApiWithPendingChangesRelationsToAndFromMap } from '@newStore/documentApi/documentApiSelectors';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { Content } from '@generalTypes/apiTypes';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';

const AsideDevelopmentPhase: React.FC<{
  config: EditAsideDevelopmentPhase;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useReadOnly();

  useEffect(() => {
    dispatch(
      loadReferenceFrame({
        referenceFrame: referenceFrames.ontwikkelingsfasesAN,
      })
    );
  }, [dispatch]);

  const anValues = useSelector((state: RootState) => {
    const referenceFrameContent =
      state.referenceFrames[referenceFrames.ontwikkelingsfasesAN]?.content;
    if (!referenceFrameContent) {
      return [];
    }
    return Object.values<Content>(referenceFrameContent).flatMap((c) =>
      c.type === 'THEME'
        ? {
            href: c.$$meta.permalink,
            name: c.title,
          }
        : []
    );
  });

  const relation = useSelector((state: RootState) =>
    selectApiWithPendingChangesRelationsToAndFromMap(state).from[editHref]?.find(
      (r) => r.relationtype === 'REQUIRES'
    )
  );

  const initialValue = relation && anValues.find((v) => v.href === relation.to.href);

  const onChange = (selection) => {
    if (!selection) {
      if (relation) {
        dispatch(removeRelationAction(relation.key));
      }
    } else if (relation) {
      dispatch(
        patchRelationAction(relation.key, {
          to: { href: selection.href },
        })
      );
    } else {
      const newRelation = {
        relationtype: 'REQUIRES',
        strength: 'MEDIUM',
        from: {
          href: editHref,
        },
        to: {
          href: selection.href,
        },
      };
      dispatch(addRelationAction(newRelation));
    }
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <div className="row">
          <div className="col-md-12">
            {config.label && (
              <label className="control-label control-label-inline">{config.label}</label>
            )}
          </div>
          <div className="col-md-12">
            <KovResourcePicker
              localData={anValues}
              display="name"
              value={initialValue}
              clearable={true}
              disabled={readOnly}
              disableSearch={true}
              onChange={onChange}
              optionValue={(o) => o.href}
              style={{
                menu: (provided) => ({ ...provided, zIndex: 3 }),
                option: (provided) => ({ ...provided, cursor: 'pointer' }),
              }}
            />
          </div>
        </div>
      </AsideValidation>
    </div>
  );
};

AsideDevelopmentPhase.displayName = 'AsideDevelopmentPhase';

export default AsideDevelopmentPhase;

AsideDevelopmentPhase.propTypes = {
  config: any,
};
